import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import TopAwardSingle from '../topAwardSingle/TopAwardSingle'
import TopAwardDouble from '../topAwardDouble/TopAwardDouble'
import TopAwardDate from '../topAwardDate/TopAwardDate'
import { ITopAwardData, ITopAwardDateData } from 'declare/index'
import styles from './topAward.module.sass'

// Gold
import GoldTitle from 'views/activity240120/images/reward/gold_title_m_01.png'
import GoldAwardTitle1 from 'views/activity240120/images/reward/gold_award_1.png'
import GoldAwardTitle2 from 'views/activity240120/images/reward/gold_award_2.png'
import GoldAwardTitle3 from 'views/activity240120/images/reward/gold_award_3.png'
import GoldAwardTitle4 from 'views/activity240120/images/reward/gold_award_4.png'
import GoldAwardTitle5 from 'views/activity240120/images/reward/gold_award_5.png'
import GoldAwardTitle6 from 'views/activity240120/images/reward/gold_award_6.png'
import GoldAwardImage1 from 'views/activity240120/images/reward/gold/奧迪名車卡.png'
import GoldAwardImage2 from 'views/activity240120/images/reward/gold/黃金龍戒卡.png'

// Silver
import SilverTitle from 'views/activity240120/images/reward/silver_title_m_01.png'
import SilverAwardTitle1 from 'views/activity240120/images/reward/silver_award_1.png'
import SilverAwardTitle2 from 'views/activity240120/images/reward/silver_award_2.png'
import SilverAwardTitle3 from 'views/activity240120/images/reward/silver_award_3.png'
import SilverAwardTitle4 from 'views/activity240120/images/reward/silver_award_4.png'
import SilverAwardTitle5 from 'views/activity240120/images/reward/silver_award_5.png'
import SilverAwardTitle6 from 'views/activity240120/images/reward/silver_award_6.png'
import SilverAwardImage1 from 'views/activity240120/images/reward/silver/Gogoro-VIVA卡.png'
import SilverAwardImage2 from 'views/activity240120/images/reward/silver/iPhone15卡.png'

// Common
import AwardImagePurple from 'images/award/鴻運卷軸.png'
import AwardImageRed from 'images/award/福運卷軸.png'
import AwardImageBowl from 'images/award/元寶(多).png'
import AwardImageBowlFew from 'images/award/元寶(少).png'
import LiveImg from 'views/activity240120/images/reward/live.png'
import TopAwardBG01 from 'views/activity230911/images/reward/gold_award_BG_01.png'
import TopAwardBG02 from 'views/activity230911/images/reward/gold_award_BG_02.png'
import TopAwardMobileBG01 from 'views/activity230911/images/reward/gold_award_BG_mob_01.png'
import TopAwardMobileBG02 from 'views/activity230911/images/reward/gold_award_BG_mob_02.png'

const styledTopAwardSingle = {
  contentBackgroundColor: '#85070F',
  contentBorderColor: '#FF973B',
  bannerBorderColor: '#FF973B',
  bannerBackgroundColor: '#790008'
}

const goldAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle1,
    awardImg: GoldAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">奧迪名車卡</span><br class="m:hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG01,
    bgMobileImg: TopAwardMobileBG01
  },
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle2,
    awardImg: GoldAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">黃金龍戒卡</span><br class="m:hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG02,
    bgMobileImg: TopAwardMobileBG02
  }
]

const goldDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle3,
      awardImg: AwardImagePurple,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">尊榮廳 4 星紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle4,
      awardImg: AwardImagePurple,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳 8 星紫卷</span><br/>（每次 1 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle5,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 10000 顆</span><br/>（每次 3 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle6,
      awardImg: AwardImageBowlFew,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 3000 顆</span><br/>（每次 10 位）</p>'
    }
  ]
]

const sliverAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle1,
    awardImg: SilverAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA; position: relative; left: 50%; transform: translateX(-50%); display: inline-block;">Gogoro VIVA卡</span><br class="m:hidden"/><br class="hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG01,
    bgMobileImg: TopAwardMobileBG01
  },
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle2,
    awardImg: SilverAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA; position: relative; left: 50%; transform: translateX(-50%); display: inline-block;">iPhone15 卡</span><br class="m:hidden"/><br class="hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG02,
    bgMobileImg: TopAwardMobileBG02
  }
]

const sliverDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle3,
      awardImg: AwardImagePurple,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳 8 星紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle4,
      awardImg: AwardImageRed,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳 8 星紅卷</span><br/>（每次 1 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle5,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 1000 顆</span><br/>（每次 3 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle6,
      awardImg: AwardImageBowlFew,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 300 顆</span><br/>（每次 48 位）</p>'
    }
  ]
]

const awardDateData: ITopAwardDateData = {
  dateDesktopImg: null,
  dateMobileImg: null,
  bannerBorderColor: '#FF973B',
  bannerBackgroundColor: '#6B0007',
  dateString: '以下將於 2/5、2/19 進行抽獎'
}

function TopAward() {
  return (
    <section className={styles.root}>
      {/** 金券獎項 */}
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={GoldTitle} alt='gold-title' />
        </div>
      </h3>
      {goldAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      <div>
        <TopAwardDate sx={{ marginBottom: 0 }} {...awardDateData} />
        {goldDoubleData.map((el) => (
          <TopAwardDouble key={uuid()} data={el} />
        ))}
      </div>
      <p>&nbsp;</p>
      <p>
        <span>● 奧迪名車卡：價值 110,000,000 金幣。</span>
        <br />
        <span>● 黃金龍戒卡：價值 6,666,600 金幣。</span>
      </p>
      <p>&nbsp;</p>
      {/** 銀券獎項 */}
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={SilverTitle} alt='gold-title' />
        </div>
      </h3>
      {sliverAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      <div>
        <TopAwardDate sx={{ marginBottom: 0 }} {...awardDateData} />
        {sliverDoubleData.map((el) => (
          <TopAwardDouble key={uuid()} data={el} />
        ))}
      </div>
      <p>&nbsp;</p>
      <p>
        <span>● Gogoro VIVA 卡：價值 4,998,000 金幣。</span>
        <br />
        <span>● iPhone15 卡：價值 2,990,000 金幣。</span>
      </p>
    </section>
  )
}

export default TopAward
