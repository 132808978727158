/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230321/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230321/images/title/t2_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/3/22(三) 12:00~2023/4/18(二) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動期間於刮刮樂遊玩任意刮刮卡，若有刮中獎金皆有高機率獲得隨機乘倍獎勵，</span>
        <span style={{ color: '#f5e61c' }}>最高加碼 30%！</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>範例：</span>
        <br />
        <span>
          玩家於 超級麻將 刮中 20,000,000 金幣，觸發乘倍獎勵 +30%，則最終玩家可領取 20,000,000 +
          6,000,000 = 26,000,000 金幣。
        </span>
        <br />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>官方保有最終解釋權及終止，調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
