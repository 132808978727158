import React from 'react'
import { v4 as uuid } from 'uuid'
import styles from './navGroup.module.sass'
import NavCard, { INavCard } from 'components/navCard/NavCard'
interface INavGroup {
  datas: Array<INavCard>
}

function NavGroup(props: INavGroup) {
  const { datas } = props

  return (
    <div className={styles.root}>
      <ul className={styles.navList}>
        {datas.map((data, index) => (
          <li key={uuid()}>
            <NavCard index={index} {...data} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NavGroup
