import React from 'react'
import eightTeenPlus from 'images/common/rate18+.png'
import clsx from 'clsx'
import styles from './footer.module.sass'

function Footer() {
  return (
    <footer className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <span className={styles.copyright}>
            Copyright © 2021 ~ 2024 JIXI TECHNOLOGY CO., LTD. All Rights Reserved.
          </span>
          <br />
          <span className={styles.announcement}>
            本遊戲情節涉及牌類及益智娛樂類，非現金交易賭博，使用者請勿進行非法遊戲幣交易。
            注意使用時間、避免沉迷、遊戲虛擬情節請勿模仿。遊戲為免費使用，遊戲另供購買虛擬遊戲幣、物品等付費服務。限制行為能力人及無行為能力者，消費前應經法定代理人同意或代為之。
          </span>
        </div>
        <div>
          <div className={clsx(styles.rating, 'relative')}>
            <span className='image_obj'>
              <img src={eightTeenPlus} alt='18+' />
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
