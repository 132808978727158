import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import TopAwardSingle from '../topAwardSingle/TopAwardSingle'
import TopAwardDouble from '../topAwardDouble/TopAwardDouble'
import TopAwardDate from '../topAwardDate/TopAwardDate'
import { ITopAwardData, ITopAwardDateData } from 'declare/index'
import styles from './topAward.module.sass'

// Gold
import GoldTitle from 'views/activity240903/images/reward/0_gold_award.png'
import GoldAwardTitle1 from 'views/activity240903/images/reward/gold_award_1.png'
import GoldAwardTitle2 from 'views/activity240903/images/reward/gold_award_2.png'
import GoldAwardTitle3 from 'views/activity240903/images/reward/double_gold_award_1.png'
import GoldAwardTitle4 from 'views/activity240903/images/reward/double_gold_award_2.png'
import GoldAwardTitle5 from 'views/activity240903/images/reward/double_gold_award_3.png'
import GoldAwardTitle6 from 'views/activity240903/images/reward/double_gold_award_4.png'
import GoldAwardTitle7 from 'views/activity240903/images/reward/double_gold_award_5.png'
import GoldAwardTitle8 from 'views/activity240903/images/reward/double_gold_award_6.png'
import GoldAwardImage1 from 'views/activity240903/images/reward/gold/BMW名車卡.png'
import GoldAwardImage2 from 'views/activity240903/images/reward/gold/開運金條卡.png'

// Silver
import SilverTitle from 'views/activity240903/images/reward/0_silver_award.png'
import SilverAwardTitle1 from 'views/activity240903/images/reward/silver_award_1.png'
import SilverAwardTitle2 from 'views/activity240903/images/reward/silver_award_2.png'
import SilverAwardTitle3 from 'views/activity240903/images/reward/double_silver_award_1.png'
import SilverAwardTitle4 from 'views/activity240903/images/reward/double_silver_award_2.png'
import SilverAwardTitle5 from 'views/activity240903/images/reward/double_silver_award_3.png'
import SilverAwardTitle6 from 'views/activity240903/images/reward/double_silver_award_4.png'
import SilverAwardTitle7 from 'views/activity240903/images/reward/double_silver_award_5.png'
import SilverAwardTitle8 from 'views/activity240903/images/reward/double_silver_award_6.png'
import SilverAwardImage1 from 'views/activity240903/images/reward/silver/Gogoro-VIVA卡.png'
import SilverAwardImage2 from 'views/activity240903/images/reward/silver/iPhone15卡.png'

// Common
import AwardImagePurple from 'images/award/鴻運卷軸.png'
import AwardImagePurpleFour from 'images/award/尊榮紫卷_4星.png'
import AwardImagePurpleEight from 'images/award/貴賓紫卷_8星.png'
import AwardImageRedEight from 'images/award/貴賓紅卷_8星.png'
import AwardImageDaJungPurpleEight from 'images/award/大眾紫卷_8星.png'
import AwardImageDaJungRedEight from 'images/award/大眾紅卷_8星.png'
import AwardImageFishGame14 from 'images/award/貴賓廳龍炮卡_14星.png'
import AwardImageFishGame15 from 'images/award/貴賓廳龍炮卡_15星.png'
import AwardImageBowl from 'images/award/元寶(多).png'
import AwardImageBowlFew from 'images/award/元寶(少).png'
import AwardImageEightStarTree from 'images/award/大眾廳招財樹_8星.png'
import LiveImg from 'views/activity240903/images/reward/draw time.png'
import TopAwardBG01 from 'views/activity230911/images/reward/gold_award_BG_01.png'
import TopAwardBG02 from 'views/activity230911/images/reward/gold_award_BG_02.png'
import TopAwardMobileBG01 from 'views/activity230911/images/reward/gold_award_BG_mob_01.png'
import TopAwardMobileBG02 from 'views/activity230911/images/reward/gold_award_BG_mob_02.png'

const styledTopAwardSingle = {
  contentBackgroundColor: '#1D006F',
  contentBorderColor: '#00B6DE',
  bannerBorderColor: '#00B6DE',
  bannerBackgroundColor: '#3621B6'
}

const goldAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle1,
    awardImg: GoldAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">BMW名車卡</span><br class="m:hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG01,
    bgMobileImg: TopAwardMobileBG01
  },
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle2,
    awardImg: GoldAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">開運金條卡</span><br class="m:hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG02,
    bgMobileImg: TopAwardMobileBG02
  }
]

const goldTripleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle3,
      awardImg: AwardImagePurpleFour,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">尊榮廳4★紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle4,
      awardImg: AwardImagePurpleEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8★紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle5,
      awardImg: AwardImageRedEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8★紅卷</span><br/>（每次 1 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle6,
      awardImg: AwardImageFishGame15,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳15★龍砲</span><br/>（每次 2 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle7,
      awardImg: AwardImageFishGame14,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳14★龍砲</span><br/>（每次 5 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle8,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 3000 個</span><br/>（每次 10 位）</p>'
    }
  ]
]

const goldDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle3,
      awardImg: AwardImagePurple,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">尊榮廳4★紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle4,
      awardImg: AwardImagePurpleEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8★紫卷</span><br/>（每次 1 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle5,
      awardImg: AwardImageRedEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8★紅卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle6,
      awardImg: AwardImageFishGame15,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳15★龍砲</span><br/>（每次 2 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle7,
      awardImg: AwardImageFishGame14,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳14★龍砲</span><br/>（每次 5 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle8,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 3000 個</span><br/>（每次 10 位）</p>'
    }
  ]
]

const sliverAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle1,
    awardImg: SilverAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA; position: relative; left: 50%; transform: translateX(-50%); display: inline-block;">Gogoro VIVA卡</span><br class="m:hidden"/><br class="hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG01,
    bgMobileImg: TopAwardMobileBG01
  },
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle2,
    awardImg: SilverAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA; position: relative; left: 50%; transform: translateX(-50%); display: inline-block;">iPhone15 卡</span><br class="m:hidden"/><br class="hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG02,
    bgMobileImg: TopAwardMobileBG02
  }
]

const sliverTripleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle3,
      awardImg: AwardImageDaJungPurpleEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8★紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle4,
      awardImg: AwardImageDaJungRedEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8★紅卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle5,
      awardImg: AwardImageEightStarTree,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8★招財樹</span><br/>（每次 2 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle6,
      awardImg: AwardImageFishGame15,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳15★龍砲</span><br/>（每次 3 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle7,
      awardImg: AwardImageFishGame14,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳14★龍砲</span><br/>（每次 5 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle8,
      awardImg: AwardImageBowlFew,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 300 個</span><br/>（每次 80 位）</p>'
    }
  ]
]

const sliverDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle3,
      awardImg: AwardImageDaJungPurpleEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8★紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle4,
      awardImg: AwardImageDaJungRedEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8★紅卷</span><br/>（每次 1 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle5,
      awardImg: AwardImageEightStarTree,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8★招財樹</span><br/>（每次 2 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle6,
      awardImg: AwardImageFishGame15,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳15★龍砲</span><br/>（每次 3 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle7,
      awardImg: AwardImageFishGame14,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳14★龍砲</span><br/>（每次 5 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle8,
      awardImg: AwardImageBowlFew,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 300 個</span><br/>（每次 80 位）</p>'
    }
  ]
]

const awardDateData: ITopAwardDateData = {
  dateDesktopImg: null,
  dateMobileImg: null,
  bannerBorderColor: '#00B6DE',
  bannerBackgroundColor: '#460796',
  dateString: '以下於 9/18、 10/2 進行抽獎'
}

function TopAward() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 980px)').matches)
    }

    // 初始化設定
    handleResize()

    // 監聽螢幕變化
    window.addEventListener('resize', handleResize)

    // 清理事件監聽器
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const goldDataToRender = isMobile ? goldDoubleData : goldTripleData

  const silverDataToRender = isMobile ? sliverDoubleData : sliverTripleData

  return (
    <section className={styles.root}>
      {/** 金券獎項 */}
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={GoldTitle} alt='gold-title' />
        </div>
      </h3>
      {goldAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      <div>
        <TopAwardDate sx={{ marginBottom: 0 }} {...awardDateData} />
        {goldDataToRender.map((el) => (
          <TopAwardDouble key={uuid()} data={el} />
        ))}
      </div>
      <p>&nbsp;</p>
      <p>
        <span>● BMW名車卡：價值150,000,000金幣。</span>
        <br />
        <span>● 開運金條卡：價值 6,888,800 金幣。</span>
        <div>獎項圖僅供參考，實際發送為遊戲金幣。</div>
      </p>
      <p>&nbsp;</p>
      {/** 銀券獎項 */}
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={SilverTitle} alt='gold-title' />
        </div>
      </h3>
      {sliverAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      <div>
        <TopAwardDate sx={{ marginBottom: 0 }} {...awardDateData} />
        {silverDataToRender.map((el) => (
          <TopAwardDouble key={uuid()} data={el} />
        ))}
      </div>
      <p>&nbsp;</p>
      <p>
        <span>● Gogoro VIVA 卡：價值 4,998,000 金幣。</span>
        <br />
        <span>● iPhone15 卡：價值 2,990,000 金幣。</span>
        <div>獎項圖僅供參考，實際發送為遊戲金幣。</div>
      </p>
    </section>
  )
}

export default TopAward
