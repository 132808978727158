import React from 'react'

import TitleM from 'views/activity240903/images/banner/01_KV_mob.png'
import FullImageM from 'views/activity240903/images/banner/02_主視覺_mob.png'
import FullImageTopL_D from 'views/activity240903/images/banner/03_前景_web.png'
import FullImageRightTop_Coin from 'views/activity240903/images/banner/02_金幣1.png'
import FullImageRightBottom_Coin from 'views/activity240903/images/banner/02_金幣3.png'
import FullImageTopR_D from 'views/activity240903/images/banner/02_金幣2.png'
import clsx from 'clsx'
import styles from './header.module.sass'
import useHeaderAnimationToggle from 'hook/useHeaderAnimationToggle'

function Header() {
  const [headerAnimationIsActive] = useHeaderAnimationToggle()

  return (
    <header className={styles.root}>
      <div className={styles.fullImageMobile}>
        <div className='image_obj'>
          <img src={FullImageM} alt='full-img-m' />
        </div>
        <div className='image_obj'>
          <img src={TitleM} alt='title-m' />
        </div>
      </div>
      <div className={styles.fullImageDesktop}>
        <div className={styles.topL}>
          <div className='image_obj'>
            <img src={FullImageTopL_D} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.rightTopCoin}>
          <div className='image_obj'>
            <img src={FullImageRightTop_Coin} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.rightBottomCoin}>
          <div className='image_obj'>
            <img src={FullImageRightBottom_Coin} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.topR}>
          <div className='image_obj'>
            <img src={FullImageTopR_D} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.carContainer}>
          <div className={clsx(styles.carAnim, headerAnimationIsActive && styles.isActive)}>
            <div className={styles.car}>
              <div className={styles.star1}></div>
              <div className={styles.star2}></div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}></div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.scanLight}></div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
