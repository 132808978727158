import variables from 'theme/common/variables.module.sass'
import React, { useState, useEffect } from 'react'
import imgGoTop from 'images/common/Button_TOP_purple.png@1.5x.png'
import styles from './goTop.module.sass'

const ICON_SHOW_Y = 100

interface IGoTop {
  colorFilter?: string
  zIndex: number
}

function GoTop(props: IGoTop) {
  const { zIndex, colorFilter } = props
  const [visible, setVisible] = useState<boolean>(false)

  const toggleVisible = () => {
    const scrolled = window.pageYOffset

    if (!visible && scrolled > ICON_SHOW_Y) {
      setVisible(true)
    } else if (visible && scrolled <= ICON_SHOW_Y) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible)
    return () => {
      window.removeEventListener('scroll', toggleVisible)
    }
  })

  return (
    <div
      className={styles.root}
      style={{
        visibility: visible ? 'visible' : 'hidden',
        opacity: visible ? 1 : 0,
        zIndex,
        filter: colorFilter
      }}
    >
      <button type='button' className={styles.scrollTop} onClick={scrollToTop}>
        <picture className='image_obj'>
          <source srcSet={imgGoTop} media={`(max-width: ${variables.mobileWidth})`} />
          <img src={imgGoTop} alt='GoTop' />
        </picture>
      </button>
    </div>
  )
}

export default GoTop
