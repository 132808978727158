import ActivityDate from 'views/activity240903/images/title/t1.png'
import ActivityMethod from 'views/activity240903/images/title/t2.png'
import ActivityAward from 'views/activity240903/images/title/t3.png'
import BlackCard from 'images/vipCard/black.png'
import EternityCard from 'images/vipCard/Eternity.png'
import BronzeCard from 'images/vipCard/bronze.png'
import CrystalCard from 'images/vipCard/crystal.png'
import DiamondCard from 'images/vipCard/diamond.png'
import GoldCard from 'images/vipCard/gold.png'
import SilverCard from 'images/vipCard/silver.png'
import PlatinumCard from 'images/vipCard/platinum.png'
import IronCard from 'images/vipCard/iron.png'
import Coin from 'images/common/coin_gold_01.png'
import Gem from 'images/common/gold-yuanbao_01.png'
import LuckyIngot from 'images/common/開運元寶.png'
import LuckyMoneySeed from 'images/common/開運錢母.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2024/9/4(三) 12:00~2024/10/1(二) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          玩家於活動期間登入即可進行簽到。玩家可於每日凌晨00:00後，於每日簽到頁點擊領取獎勵。每日簽到獎勵贈送開運錢母或元寶！
          <br />
          每累積簽到滿7日，可獲得額外的免費轉盤！還有購買超優惠進階轉盤獲得紫卷的機會！好康千萬別錯過！
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>※ 玩家會員層級需達到銀卡以上才可進行簽到。</span>
      </p>
      <p>
        <span style={{ color: '#f5e61c' }}>
          ※ 玩家可於每日凌晨00:00後，於每日簽到頁點擊領取獎勵。
        </span>
      </p>
      <p>
        <span style={{ color: '#f5e61c' }}>※ 轉盤的累積登入天數，無法跨越檔期累積。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <table
        className='table-bg-6 table-radius'
        style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              colSpan={5}
              className='table-rounded-tl table-title-bg-6 table-rounded-tr'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              <p>每日簽到獎勵</p>
              <span>(依照會員層級獲得以下其一)</span>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              會員層級
            </th>
            <th
              colSpan={2}
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={LuckyMoneySeed} alt='lucky-money-seed' width={30} />
                <span>開運錢母</span>
              </div>
            </th>
            <th
              colSpan={2}
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={LuckyIngot} alt='lucky-ingot' width={30} />
                <span>開運元寶</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={BlackCard} alt='black-card' width={60} />
              <br />
              尊爵
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Coin} alt='coin' width={30} />
                <span>2,000 ~ 100,000</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Gem} alt='gem' width={30} />
                <span>25 ~ 500個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={EternityCard} alt='black-card' width={60} />
              <br />
              極致
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Coin} alt='coin' width={30} />
                <span>1,500 ~ 50,000</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Gem} alt='gem' width={30} />
                <span>20 ~ 300個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={CrystalCard} alt='black-card' width={60} />
              <br />
              晶鑽
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Coin} alt='coin' width={30} />
                <span>1,000 ~ 20,000</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Gem} alt='gem' width={30} />
                <span>10 ~ 150個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={DiamondCard} alt='black-card' width={60} />
              <br />
              紅鑽
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Coin} alt='coin' width={30} />
                <span>800 ~ 10,000</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Gem} alt='gem' width={30} />
                <span>8 ~ 75個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={PlatinumCard} alt='black-card' width={60} />
              <br />
              白金
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Coin} alt='coin' width={30} />
                <span>500 ~ 5,000</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Gem} alt='gem' width={30} />
                <span>5 ~ 35個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={GoldCard} alt='black-card' width={60} />
              <br />金
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Coin} alt='coin' width={30} />
                <span>300 ~ 3,000</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Gem} alt='gem' width={30} />
                <span>3 ~ 25個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={SilverCard} alt='black-card' width={60} />
              <br />銀
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Coin} alt='coin' width={30} />
                <span>200 ~ 2,000</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Gem} alt='gem' width={30} />
                <span>2 ~ 15個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={BronzeCard} alt='black-card' width={60} />
              <br />
              <img src={IronCard} alt='black-card' width={60} />
              <br />
              銅&鐵
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Coin} alt='coin' width={30} />
                <span>150 ~ 1,000</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img src={Gem} alt='gem' width={30} />
                <span>1 ~ 8個</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>

      <table
        className='table-bg-6 table-radius'
        style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              會員層級
            </th>
            <th
              colSpan={4}
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              <p>
                <span>7日轉盤獎勵</span>
                <br />
              </p>
              <span>(依照會員層級獲得以下其一)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={BlackCard} alt='black-card' width={60} />
              <br />
              尊爵
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>金幣</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span style={{ width: '100%' }}>30,000~150,000</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>元寶</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>200~1,000個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <span>道具</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>
                  貴賓廳1★ 鴻運卷軸、福運卷軸、吉運卷軸、招財樹、 翻倍令牌(2、3、5倍/10、20場)
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={EternityCard} alt='eternity-card' width={60} />
              <br />
              極緻
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>金幣</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span style={{ width: '100%' }}>10,000~70,000</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>元寶</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span>100~500個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>道具</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>
                  大眾廳8★ 鴻運卷軸、福運卷軸、吉運卷軸、招財樹、
                  <br />
                  翻倍令牌(2、3、5倍/10、20場)
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={CrystalCard} alt='black-card' width={60} />
              <br />
              晶鑽
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>金幣</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span style={{ width: '100%' }}>8,000~50,000</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>元寶</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span>50~350個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>道具</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>
                  大眾廳5★ 鴻運卷軸、福運卷軸、吉運卷軸、招財樹、
                  <br />
                  翻倍令牌(2、3、5倍/10、20場)
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={DiamondCard} alt='diamond-card' width={60} />
              <br />
              紅鑽
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>金幣</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span style={{ width: '100%' }}>5,000~30,000</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>元寶</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span>25~100個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>道具</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>
                  大眾廳3★ 鴻運卷軸、福運卷軸、吉運卷軸、招財樹、
                  <br />
                  翻倍令牌(2、3、5倍/10、20場)
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={PlatinumCard} alt='platinum-card' width={60} />
              <br />
              白金
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>金幣</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span style={{ width: '100%' }}>2,000~10,000</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>元寶</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span>15~50個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>道具</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>
                  大眾廳2★ 鴻運卷軸、福運卷軸、吉運卷軸、招財樹、
                  <br />
                  翻倍令牌(2、3、5倍/10、20場)
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={GoldCard} alt='gold-card' width={60} />
              <br />金
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>金幣</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span style={{ width: '100%' }}>1,500~8,000</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>元寶</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span>10~35個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>道具</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>
                  大眾廳1★ 鴻運卷軸、福運卷軸、吉運卷軸、招財樹、
                  <br />
                  翻倍令牌(2、3、5倍/10、20場)
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={SilverCard} alt='silver-card' width={60} />
              <br />銀
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>金幣</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span style={{ width: '100%' }}>1,000~5,000</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>元寶</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span>5~20個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>道具</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>
                  歡樂廳8★ 鴻運卷軸、福運卷軸、吉運卷軸、招財樹、
                  <br />
                  翻倍令牌(2、3、5倍/10、20場)
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <img src={BronzeCard} alt='bronze-card' width={60} />
              <br />
              <img src={IronCard} alt='iron-card' width={60} />
              <br />
              銅&鐵
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>金幣</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span>500~2,000</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>元寶</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span>2~10個</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <span>道具</span>
              </div>
            </td>
            <td colSpan={2} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left'
                }}
              >
                <span>
                  歡樂廳8★ 鴻運卷軸、福運卷軸、吉運卷軸、招財樹、
                  <br />
                  翻倍令牌(2、3、5倍/10、20場)
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>【注意事項】</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>當日簽到須於晚上23:59前點擊領取，逾時無法補簽。</li>
        <li>
          若於觸發轉盤時發生中斷遊戲，系統將自動進行旋轉，並將獎勵送進背包或玩家表底。您可於簽到記錄頁查看歷史獲得獎勵的清單。
        </li>
        <li>
          若玩家打開此活動頁，未主動簽到就關閉頁面，系統將自動幫玩家簽到領取獎勵後再關閉頁面。
        </li>
        <li>付費轉盤購買相關內容請詳見遊戲內活動介面之說明。</li>
        <li>
          部分優惠商品每個遊戲帳號有限制購買次數，成功購買後將無法更換。
          <br />
          ※特別提醒您，官網公告之首儲優惠活動每個遊戲帳號僅限購一次，購買其他任意商品(不論平台方)都將永久失去超值首儲方案的購買資格。
        </li>
        <li>部分優惠商品為限時販售，活動時間判定以GMT+8 為主，成功購買後將無法更換。 </li>
        <li>部分優惠商品為機會中獎商品，消費者購買或參與活動不代表即可獲得特定商品。 </li>
        <li>
          限時限次活動商品以「收款平台方收據上的購買時間」判斷是否成功以優惠內容購買(舉例：安卓、iOS提供收據上的付款時間)，因此若發生付款失敗、交易取消、延遲到帳等收款平台方之狀況，該筆交易將無法獲得活動商品優惠內容，系統將自動以付款金額1:1比值給予對應點數發送。
        </li>
        <li>
          由於依照平台方提供收據上的紀錄時間作為判斷依據來扣除限購次數，故當您點選購買後，如平台方發生結帳失敗、延遲發送收據或其他緣由造成未完成結帳，品項不分儲值管道均維持顯示限購優惠相關資訊(包含首儲亦適用此情形)。
        </li>
        <li>
          當購買複數個顯示限制購買次數之商品時(含首儲)，於結帳後將依照各項儲值管道處理之流程及速度，由系統自動判定，僅有符合限購次數之商品可獲得優惠，其他品項將依照付款金額1:1比值給予對應點數。
        </li>
        <li>
          若玩家同時於不同管道儲值繳費，有小機率發生因系統延遲，而導致最後獲得優惠商品非第一筆交易之商品。
        </li>
        <li>
          建議玩家進行儲值時，先購買單一品項並結帳，於確認收到購買商品後再進行第二筆儲值或是切換帳號行為，以確保最終獲得商品與您認知一致。
        </li>
        <li>
          儲值優惠內容以聚寶Online官方網站公告為主。如因故導致無法執行時，本公司有權修改、暫停或停止儲值優惠之所有相關內容。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
