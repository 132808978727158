/* eslint-disable arrow-parens */
import { create } from 'zustand'

interface IRate18Alert {
  open: boolean
  setR18AlertStatus: (key: boolean) => void
  getR18AlertStatus: () => boolean
}

const useStore = create<IRate18Alert>((set, get) => ({
  open: false,
  setR18AlertStatus: (key: boolean) => {
    set(() => ({ open: key }))
  },
  getR18AlertStatus: () => get().open
}))

export default useStore
