/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230611/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230611/images/title/t2_pc_01.png'
import ActivitySpecificGame from 'views/activity230611/images/title/t4_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/6/12(三) 12:00~2023/6/18(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivitySpecificGame}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivitySpecificGame}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>【射日之戰】</p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>
          <strong>龍榜：</strong>
        </span>
        <br />
        <span>活動期間遊玩指定遊戲，將於</span>
        <span style={{ color: '#f5e61c' }}>每週日</span>
        <span> 24:00 後，依照「主打榜-龍榜」各廳館</span>
        <span style={{ color: '#f5e61c' }}>累積贏分排名</span>
        <span>
          結算，於指定名次內贈送該廳館鴻運卷軸 1
          個！若有同分的狀況，則以時間先後順序排名，獎勵將於結算後 5 分鐘內發送至信箱。
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-4 table-radius'>
        <thead>
          <tr>
            <th colSpan={3} className='table-rounded-tl table-title-bg-4 table-rounded-tr'>
              龍榜獎勵
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-4'>廳館</th>
            <th className='table-title-bg-4'>名次</th>
            <th className='table-title-bg-4'>獎勵</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3}>貴賓廳</td>
            <td>第 1 名</td>
            <td>貴賓廳 3★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 2 名</td>
            <td>貴賓廳 2★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 3 名</td>
            <td>貴賓廳 1★鴻運卷軸</td>
          </tr>

          <tr>
            <td rowSpan={5}>大眾廳</td>
            <td>第 1 名</td>
            <td>大眾廳 5★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 2 名</td>
            <td>大眾廳 4★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 3 名</td>
            <td>大眾廳 3★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 4 名</td>
            <td>大眾廳 2★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 5 名</td>
            <td>大眾廳 1★鴻運卷軸</td>
          </tr>

          <tr>
            <td rowSpan={4} className='table-rounded-bl'>
              歡樂廳
            </td>
            <td>第 1 名</td>
            <td>歡樂廳 8★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 2 名</td>
            <td>歡樂廳 7★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 3 名</td>
            <td>歡樂廳 6★鴻運卷軸</td>
          </tr>
          <tr>
            <td>第 4~10 名</td>
            <td className='table-rounded-br'>歡樂廳 5★鴻運卷軸</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>
          <strong>虎榜：</strong>
        </span>
        <br />
        <span>活動期間遊玩指定遊戲，將於</span>
        <span style={{ color: '#f5e61c' }}>每日</span>
        <span> 24:00 後，依照「主打榜-虎榜」各廳館</span>
        <span style={{ color: '#f5e61c' }}>單把贏分排名</span>
        <span>
          結算，於指定名次內贈送該廳館福運卷軸 1
          個！若有同分的狀況，則以時間先後順序排名，獎勵將於結算後 5 分鐘內發送至信箱。
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-4 table-radius'>
        <thead>
          <tr>
            <th colSpan={3} className='table-rounded-tl table-title-bg-4 table-rounded-tr'>
              虎榜獎勵
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-4'>廳館</th>
            <th className='table-title-bg-4'>名次</th>
            <th className='table-title-bg-4'>獎勵</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3}>貴賓廳</td>
            <td>第 1 名</td>
            <td>貴賓廳 3★福運卷軸</td>
          </tr>
          <tr>
            <td>第 2 名</td>
            <td>貴賓廳 2★福運卷軸</td>
          </tr>
          <tr>
            <td>第 3 名</td>
            <td>貴賓廳 1★福運卷軸</td>
          </tr>

          <tr>
            <td rowSpan={5}>大眾廳</td>
            <td>第 1 名</td>
            <td>大眾廳 5★福運卷軸</td>
          </tr>
          <tr>
            <td>第 2 名</td>
            <td>大眾廳 4★福運卷軸</td>
          </tr>
          <tr>
            <td>第 3 名</td>
            <td>大眾廳 3★福運卷軸</td>
          </tr>
          <tr>
            <td>第 4 名</td>
            <td>大眾廳 2★福運卷軸</td>
          </tr>
          <tr>
            <td>第 5 名</td>
            <td>大眾廳 1★福運卷軸</td>
          </tr>

          <tr>
            <td rowSpan={4} className='table-rounded-bl'>
              歡樂廳
            </td>
            <td>第 1 名</td>
            <td>歡樂廳 8★福運卷軸</td>
          </tr>
          <tr>
            <td>第 2 名</td>
            <td>歡樂廳 7★福運卷軸</td>
          </tr>
          <tr>
            <td>第 3 名</td>
            <td>歡樂廳 6★福運卷軸</td>
          </tr>
          <tr>
            <td>第 4~10 名</td>
            <td className='table-rounded-br'>歡樂廳 5★福運卷軸</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        註：「單把贏分」之計算，將依照遊戲幣入錶底的時機算做 1
        把，因此免費遊戲、吉運祝福、福運祝福、鴻運祝福...等特色，將在特色結束後計算為 1
        把；招財樹與翻倍令牌則每次消耗特色剩餘轉數時算做 1 把。
      </p>
      <p>&nbsp;</p>
      <p>官方保有最終解釋權及終止，調整或修改活動規則的權益。</p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
