import React from 'react'
import Navbar from 'views/navbar/Navbar'
import Footer from 'views/footer/Footer'
import styles from './baseLayout.module.sass'

interface IBaseLayoutProps {
  children: JSX.Element
}

function BaseLayout({ children }: IBaseLayoutProps) {
  return (
    <div className={styles.root}>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

export default BaseLayout
