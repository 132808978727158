/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router'
import styles from './activityContainer.module.sass'
import { INavCard } from 'components/navCard/NavCard'
import useNavCardSelectStore from '../../../zustand/navCardSelectStore/index'
import ActivityWrapper from '../activityWrapper/ActivityWrapper'
import { ActivityTheme } from 'declare/theme'

interface IActivityContainer {
  activityData: Array<INavCard>
  colorTheme?: ActivityTheme
  contentBoxMarginTop?: string
}

function ActivityContainer(props: IActivityContainer) {
  const { activityData, colorTheme } = props
  const rootEl = useRef(null)
  const { id } = useParams<string>()
  const urlID = parseInt(id, 10)
  const urlHash = useLocation().hash
  const { setCurNavIndex, setUnFindNavCardIndex } = useNavCardSelectStore()
  const expectedRoute = parseInt(id, 10) > 0 && parseInt(id, 10) <= activityData.length
  const activityContentComponent = useMemo(() => {
    if (expectedRoute) {
      return activityData[urlID - 1].component
    }

    if (id === undefined) {
      return activityData[0].component
    }

    return null
  }, [expectedRoute, id, activityData, urlID])

  const enable = useMemo(() => {
    // when route is /activity
    if (id === undefined) {
      if (!activityData[0].isBlock) {
        return true
      }
    }

    // when route is /activity/:id
    if (expectedRoute) {
      if (!activityData[urlID - 1].isBlock) {
        return true
      }
    }

    return false
  }, [activityData, expectedRoute, id, urlID])

  const conditionTitleRender = () => {
    if (id === undefined) {
      return <img src={activityData[0].imgTitle} alt='title' />
    }

    if (activityData[urlID - 1]) {
      return <img src={activityData[urlID - 1].imgTitle} alt='title' />
    }

    return null
  }

  useEffect(() => {
    if (urlHash && expectedRoute && !activityData[urlID - 1].isBlock) {
      window.scrollTo(0, rootEl.current.offsetTop)
    }
  }, [activityData, urlID, expectedRoute, urlHash])

  useEffect(() => {
    if (expectedRoute) {
      setCurNavIndex(urlID)
    } else if (id === undefined) {
      setCurNavIndex(1)
    } else {
      setUnFindNavCardIndex()
    }
  }, [activityData.length, urlID, expectedRoute, setCurNavIndex, setUnFindNavCardIndex, id])

  return (
    <>
      {enable && (
        <section ref={rootEl} className={styles.root}>
          <div className={styles.wrapper}>
            <h2 className={styles.title}>{conditionTitleRender()}</h2>
            <div className={styles.contentBox} style={{ marginTop: props.contentBoxMarginTop }}>
              <ActivityWrapper colorTheme={colorTheme}>{activityContentComponent}</ActivityWrapper>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ActivityContainer
