import React from 'react'
import ActivityDate from 'views/activity240120/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity240120/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity240120/images/title/t3_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2024/2/8(四) 00:00~2024/2/14(三) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>期間內每天</span>
        <span style={{ color: '#f5e61c' }}>遊玩魚機累積押注額</span>
        <span>
          達「捕魚大挑戰」活動介面門檻，將擇一最優階段獎勵發送【海皇爭霸2-王者對決】稀有魚機道具！
        </span>
        <br />
        <br />
        <span>※ 獎勵將於隔日晚上 18 點前，陸續發送至玩家信箱。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          魚機福袋可能開出道具：電磁砲、強力電磁砲、威力電磁砲、鑽頭砲、強力鑽頭砲、威力鑽頭砲、魚雷砲(20發)
          、魚雷砲(30發)、魚雷砲(60發)。
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-5 table-rounded-tl'>階段</th>
            <th className='table-title-bg-5'>累積押注額</th>
            <th className='table-title-bg-5 table-rounded-tr'>道具廳館星級</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>10</td>
            <td>35,000,000</td>
            <td>貴賓廳9★</td>
          </tr>
          <tr>
            <td>9</td>
            <td>25,000,000</td>
            <td>貴賓廳6★</td>
          </tr>
          <tr>
            <td>8</td>
            <td>15,000,000</td>
            <td>貴賓廳3★</td>
          </tr>
          <tr>
            <td>7</td>
            <td>10,000,000</td>
            <td>貴賓廳1★</td>
          </tr>
          <tr>
            <td>6</td>
            <td>5,000,000</td>
            <td>大眾廳9★</td>
          </tr>
          <tr>
            <td>5</td>
            <td>3,000,000</td>
            <td>大眾廳6★</td>
          </tr>
          <tr>
            <td>4</td>
            <td>2,000,000</td>
            <td>大眾廳4★</td>
          </tr>
          <tr>
            <td>3</td>
            <td>1,000,000</td>
            <td>大眾廳2★</td>
          </tr>
          <tr>
            <td>2</td>
            <td>500,000</td>
            <td>大眾廳1★</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>1</td>
            <td>100,000</td>
            <td className='table-rounded-br'>歡樂廳9★</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
