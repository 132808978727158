/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230321/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230321/images/title/t2_pc_01.png'
import ActivityExchange from 'views/activity230321/images/title/t5_pc_01.png'
import KeyGolden from 'images/key/key_gold.png'
import KeySilver from 'images/key/key_silver.png'
import KeyCopper from 'images/key/key_copper.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/4/1(六) 00:00~2023/4/7(五) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityExchange}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityExchange}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>活動開始~2023/4/10(一) 23:59</p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>活動期間玩家可於「獵寶戲草原」活動頁面，依廳館使用獲得的寶藏鑰匙進行：</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          升級藏寶點：升級藏寶點可提升挖掘寶物時獲得的道具星等。強化到最高等級保證獲得該廳館 7~8
          星道具或元寶。每次升級藏寶點所需的寶藏鑰匙數量會隨升級次數變動。
        </li>
        <li>
          挖掘寶物：根據當下的藏寶點等級進行挖掘並獲得獎勵，藏寶點等級將於每次挖掘寶物後進行重置。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>※ 歡樂廳每日可挖掘寶物次數為一次。</span>
        <br />
        <span style={{ color: '#f5e61c' }}>※ 藏寶點等級越高更有機會找到紅紫卷！</span>
        <br />
        <span style={{ color: '#f5e61c' }}>
          ※ 範例：小明從貴賓廳老虎機累積獲得 18 個金鑰匙，此時小明若先消耗 8
          個金鑰匙「升級藏寶點」，將藏寶點升級至 LV2。接著選擇消耗 10
          個金鑰匙「挖掘寶物」並獲得獎勵後，藏寶點等級會重置為 LV1。
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-3 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tr table-title-bg-3 table-rounded-tl'>
              貴賓廳
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-3'>藏寶點等級</th>
            <th className='table-title-bg-3'>可獲得獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>LV 4</td>
            <td>
              <span>7～8★ 道具</span>
              <br />
              <span>或 2,500 個元寶</span>
            </td>
          </tr>
          <tr>
            <td>LV 3</td>
            <td>
              <span>5～8★ 道具</span>
              <br />
              <span>或 1,000 個元寶</span>
            </td>
          </tr>
          <tr>
            <td>LV 2</td>
            <td>
              <span>3～8★ 道具</span>
              <br />
              <span>或 500 個元寶</span>
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>LV 1</td>
            <td className='table-rounded-br'>
              <span>1～8★ 道具</span>
              <br />
              <span>或 300 個元寶</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-3 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tr table-title-bg-3 table-rounded-tl'>
              大眾廳
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-3'>藏寶點等級</th>
            <th className='table-title-bg-3'>可獲得獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>LV 4</td>
            <td>
              <span>7～8★ 道具</span>
              <br />
              <span>或 180 個元寶</span>
            </td>
          </tr>
          <tr>
            <td>LV 3</td>
            <td>
              <span>5～8★ 道具</span>
              <br />
              <span>或 100 個元寶</span>
            </td>
          </tr>
          <tr>
            <td>LV 2</td>
            <td>
              <span>3～8★ 道具</span>
              <br />
              <span>或 50 個元寶</span>
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>LV 1</td>
            <td className='table-rounded-br'>
              <span>1～8★ 道具</span>
              <br />
              <span>或 25 個元寶</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-3 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tr table-title-bg-3 table-rounded-tl'>
              歡樂廳
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-3'>藏寶點等級</th>
            <th className='table-title-bg-3'>可獲得獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>LV 4</td>
            <td>
              <span>7～8★ 道具</span>
              <br />
              <span>或 5 個元寶</span>
            </td>
          </tr>
          <tr>
            <td>LV 3</td>
            <td>
              <span>5～8★ 道具</span>
              <br />
              <span>或 3 個元寶</span>
            </td>
          </tr>
          <tr>
            <td>LV 2</td>
            <td>
              <span>3～8★ 道具</span>
              <br />
              <span>或 2 個元寶</span>
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>LV 1</td>
            <td className='table-rounded-br'>
              <span>1～8★ 道具</span>
              <br />
              <span>或 1 個元寶</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span>※寶藏鑰匙獲取方法：</span>
        <br />
        <span>
          玩家於各廳館老虎機押注，每把皆有機會獲得寶藏鑰匙。押注越高，寶藏鑰匙的掉落機率越高！且依廳館可獲得不同顏色的寶藏鑰匙。
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-3 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-3 table-rounded-tl'>廳館</th>
            <th className='table-title-bg-3 table-rounded-tr'>可獲得寶藏鑰匙</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>貴賓廳</td>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img style={{ width: '60px', marginRight: '1rem' }} src={KeyGolden} alt='gold-key' />
              金鑰匙
            </td>
          </tr>
          <tr>
            <td>大眾廳</td>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                style={{ width: '60px', marginRight: '1rem' }}
                src={KeySilver}
                alt='gold-silver'
              />
              銀鑰匙
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>歡樂廳</td>
            <td
              className='table-rounded-br'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                style={{ width: '60px', marginRight: '1rem' }}
                src={KeyCopper}
                alt='gold-copper'
              />
              銅鑰匙
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        活動結束後，活動介面會保留三天以便玩家兌換獎勵，但期間遊玩遊戲無法再獲得寶藏鑰匙，保留天數過後，活動材料將會由系統回收。
      </p>
      <p>&nbsp;</p>
      <p>
        <span>官方保有最終解釋權及終止，調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
