import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import styles from './topAward.module.sass'
import TopAwardSingle from 'components/topAwardSingle/TopAwardSingle'
import TopAwardDouble from 'components/topAwardDouble/TopAwardDouble'
import { ITopAwardData } from 'declare/index'

// Gold
import GoldTitle from 'views/activity230321/images/reward/gold_title_m_01.png'
import GoldAwardTitle1 from 'views/activity230321/images/reward/gold_award_1.png'
import GoldAwardTitle2 from 'views/activity230321/images/reward/gold_award_2.png'
import GoldAwardImage1 from 'views/activity230321/images/reward/gold/特斯拉MODEL3卡.png'
import GoldAwardImage2 from 'views/activity230321/images/reward/gold/犀牛墜飾卡.png'

// Silver
import SilverTitle from 'views/activity230321/images/reward/silver_title_m_01.png'
import SilverAwardTitle1 from 'views/activity230321/images/reward/silver_award_1.png'
import SilverAwardTitle2 from 'views/activity230321/images/reward/silver_award_2.png'
import SilverAwardImage1 from 'views/activity230321/images/reward/silver/Gogoro VIVA卡.png'
import SilverAwardImage2 from 'views/activity230321/images/reward/silver/iPhone14卡.png'

// Common
import AwardTitle3 from 'views/activity230321/images/reward/gold_award_3.png'
import AwardImage3 from 'images/award/鴻運卷軸.png'
import AwardTitle4 from 'views/activity230321/images/reward/gold_award_4.png'
import AwardImage4 from 'images/award/福運卷軸.png'
import AwardTitle5 from 'views/activity230321/images/reward/gold_award_5.png'
import AwardImage5 from 'images/award/招財樹.png'
import AwardTitle6 from 'views/activity230321/images/reward/gold_award_6.png'
import AwardImage6 from 'images/award/元寶(多).png'

const styledTopAwardSingle = {
  contentBackgroundColor: '#7B594A',
  contentBorderColor: '#977565',
  bannerBorderColor: '#977565',
  bannerBackgroundColor: '#491800'
}

const goldAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle1,
    awardImg: GoldAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">特斯拉卡</span><br class="m:hidden"/> 1位</p>'
  },
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle2,
    awardImg: GoldAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">金犀墜飾卡</span><br class="m:hidden"/> 2位</p>'
  }
]

const goldDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle3,
      awardImg: AwardImage3,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星紫卷</span><br class="m:block hidden"/> 5位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle4,
      awardImg: AwardImage4,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星紅卷</span><br class="m:block hidden"/>  10位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      ...styledTopAwardSingle,
      titleImg: AwardTitle5,
      awardImg: AwardImage5,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星招財樹</span><br class="m:block hidden"/>  15位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle6,
      awardImg: AwardImage6,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶3000顆</span><br class="m:block hidden"/>  35位</p>'
    }
  ]
]

const silverAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle1,
    awardImg: SilverAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">Gogoro VIVA卡</span><br class="m:hidden"/> 1位</p>'
  },
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle2,
    awardImg: SilverAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">iPhone14卡</span><br class="m:hidden"/> 2位</p>'
  }
]

const silverDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle3,
      awardImg: AwardImage3,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星紫卷</span><br class="m:block hidden"/>  15位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle4,
      awardImg: AwardImage4,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星紅卷</span><br class="m:block hidden"/>  35位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle5,
      awardImg: AwardImage5,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星招財樹</span><br class="m:block hidden"/>  50位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle6,
      awardImg: AwardImage6,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶500顆</span><br class="m:block hidden"/>  150位</p>'
    }
  ]
]

function TopAward() {
  return (
    <section className={styles.root}>
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={GoldTitle} alt='gold-title' />
        </div>
      </h3>
      {goldAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      {goldDoubleData.map((el) => (
        <TopAwardDouble key={uuid()} data={el} />
      ))}
      <p>● 特斯拉卡: 價值 150,000,000 金幣。</p>
      <p>● 金犀墜飾卡: 價值 3,888,800 金幣。</p>
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={SilverTitle} alt='silver-title' />
        </div>
      </h3>
      {silverAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      {silverDoubleData.map((el) => (
        <TopAwardDouble key={uuid()} data={el} />
      ))}
      <p>● Gogoro VIVA 卡: 價值 6,998,000 金幣。</p>
      <p>● iPhone14 卡: 價值 2,790,000 金幣。</p>
    </section>
  )
}

export default TopAward
