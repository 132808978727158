import React from 'react'
import ActivityDate from 'views/activity240120/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity240120/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity240120/images/title/t3_pc_01.png'
import ActivityDeadline from 'views/activity240120/images/title/t5_pc_01.png'
import Coin from 'images/common/coin_gold_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2024/1/22(一) 12:00~2024/1/28(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDeadline}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDeadline}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動開始~2024/1/31(三) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>
          ※
          領取期限過後，如尚有隔日券未從活動頁面上領取，則將會由系統回收。已經領取至背包中的隔日券沒有使用期限。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>玩家於活動期間累積老虎機押注額，每達成一階挑戰，可領取高價隔日券禮包！</span>
        <br />
        <span>
          門檻挑戰進度於活動期間內不重置。達成 13
          階後，解鎖無限挑戰模式，階階必得「鉅額隔日券」！無限狂領，最好康！
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>※ 使用道具不計入本次活動的押注額計算。</span>
        <br />
        <span style={{ color: '#f5e61c' }}>
          ※ 活動結束後，活動介面會保留 3 天，可進行領取獎勵。於此期間，遊玩老虎機無法再累積押注額。
        </span>
        <br />
        <span style={{ color: '#f5e61c' }}>
          ※ 活動結束 3 天後，未領取獎勵將全數由系統回收。已經領取至背包中的隔日券沒有使用期限。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-5 table-rounded-tl'>門檻</th>
            <th className='table-title-bg-5 table-rounded-tr'>獎勵</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              ∞<br />
              無限
            </td>
            <td>
              隔日券禮包
              <br />
              五十萬 1 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              500,000
            </td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              隔日券禮包
              <br />
              十萬 4 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              400,000
            </td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              隔日券禮包
              <br />
              十萬 3 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              300,000
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              隔日券禮包
              <br />
              十萬 2 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              200,000
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>
              隔日券禮包
              <br />
              十萬 1 張+五萬 1 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              150,000
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              隔日券禮包
              <br />
              五萬 2 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              100,000
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              隔日券禮包
              <br />
              五萬 1 張+一萬 2 張+五千 1 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              75,000
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              隔日券禮包
              <br />
              五萬 1 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              50,000
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              隔日券禮包
              <br />
              一萬 4 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              40,000
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              隔日券禮包
              <br />
              一萬 3 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              30,000
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              隔日券禮包
              <br />
              一萬 2 張+五千 1 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              25,000
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              隔日券禮包
              <br />
              一萬 2 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              20,000
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              隔日券禮包
              <br />
              一萬 1 張+五千 1 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              15,000
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>1</td>
            <td className='table-rounded-br'>
              隔日券禮包
              <br />
              五千 2 張
              <br />
              共價值{' '}
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />{' '}
              10,000
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        ※ 隔日券說明：
        <br />
        1. 隔日券需從背包點選使用，使用後可獲得與隔日券等值的金幣。
        <br />
        2. 每個帳號一天可使用的隔日券數量依照會員層級不同。每天 00:00 重置可使用次數。
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-5 table-rounded-tl'>會員層級</th>
            <th className='table-title-bg-5 table-rounded-tr'>每日張數</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>紅鑽卡以上</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>白金卡</td>
            <td>10 張</td>
          </tr>
          <tr>
            <td>金卡</td>
            <td>5 張</td>
          </tr>
          <tr>
            <td>銀卡</td>
            <td>2 張</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>鐵卡、銅卡</td>
            <td className='table-rounded-br'>1 張</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>3. 隔日券進入背包後，需要等待24小時後才會解鎖，未解鎖的隔日券無法使用。</p>
      <p>&nbsp;</p>
      <p>
        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
