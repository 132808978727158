import $axios from 'utils/axios'

const system = {
  toDownloadApi: async () => {
    const { data } = await $axios({
      method: 'post',
      url: '/store'
    })
    return data
  }
}
export const { toDownloadApi } = system
export default system
