/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230611/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230611/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity230611/images/title/t3_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/6/12(一) 12:00~2023/7/2(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間於【視訊百家】遊玩，凡連贏局數達標即可依押注金額獲得連贏獎金！連贏獎金將於斷連後，
        </span>
        <span style={{ color: '#f5e61c' }}>依最高連贏局數</span>
        <span>進行發放！</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>
          ※ 活動獎勵：將以信件形式，在達成條件後 10 分鐘內寄送至信箱。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-4 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-4 table-rounded-tl'>押注區間</th>
            <th className='table-title-bg-4'>連贏局數</th>
            <th className='table-title-bg-4 table-rounded-tr'>獎金內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={6}>300K 以上</td>
            <td>17 局以上</td>
            <td>
              <span>每連一局獎金再加</span>
              <br />
              <span>金幣 6,000,000 [註1]</span>
            </td>
          </tr>
          <tr>
            <td>17 局</td>
            <td>金幣 6,000,000</td>
          </tr>
          <tr>
            <td>14 局</td>
            <td>金幣 4,500,000</td>
          </tr>
          <tr>
            <td>11 局</td>
            <td>金幣 3,000,000</td>
          </tr>
          <tr>
            <td>8 局</td>
            <td>金幣 300,000</td>
          </tr>
          <tr>
            <td>5 局</td>
            <td>金幣 75,000</td>
          </tr>

          <tr>
            <td rowSpan={6}>100K~299.9K</td>
            <td>17 局以上</td>
            <td>
              <span>每連一局獎金再加</span>
              <br />
              <span>金幣 2,000,000 [註1]</span>
            </td>
          </tr>
          <tr>
            <td>17 局</td>
            <td>金幣 2,000,000</td>
          </tr>
          <tr>
            <td>14 局</td>
            <td>金幣 1,500,000</td>
          </tr>
          <tr>
            <td>11 局</td>
            <td>金幣 1,000,000</td>
          </tr>
          <tr>
            <td>8 局</td>
            <td>金幣 100,000</td>
          </tr>
          <tr>
            <td>5 局</td>
            <td>金幣 25,000</td>
          </tr>

          <tr>
            <td rowSpan={6}>30K~99.9K</td>
            <td>17 局以上</td>
            <td>
              <span>每連一局獎金再加</span>
              <br />
              <span>金幣 600,000 [註1]</span>
            </td>
          </tr>
          <tr>
            <td>17 局</td>
            <td>金幣 600,000</td>
          </tr>
          <tr>
            <td>14 局</td>
            <td>金幣 450,000</td>
          </tr>
          <tr>
            <td>11 局</td>
            <td>金幣 300,000</td>
          </tr>
          <tr>
            <td>8 局</td>
            <td>金幣 30,000</td>
          </tr>
          <tr>
            <td>5 局</td>
            <td>金幣 7,500</td>
          </tr>

          <tr>
            <td rowSpan={6}>10K~29.9K</td>
            <td>17 局以上</td>
            <td>
              <span>每連一局獎金再加</span>
              <br />
              <span>金幣 200,000 [註1]</span>
            </td>
          </tr>
          <tr>
            <td>17 局</td>
            <td>金幣 200,000</td>
          </tr>
          <tr>
            <td>14 局</td>
            <td>金幣 150,000</td>
          </tr>
          <tr>
            <td>11 局</td>
            <td>金幣 100,000</td>
          </tr>
          <tr>
            <td>8 局</td>
            <td>金幣 10,000</td>
          </tr>
          <tr>
            <td>5 局</td>
            <td>金幣 2,500</td>
          </tr>

          <tr>
            <td rowSpan={6}>3K~9.9K</td>
            <td>17 局以上</td>
            <td>
              <span>每連一局獎金再加</span>
              <br />
              <span>金幣 60,000 [註1]</span>
            </td>
          </tr>
          <tr>
            <td>17 局</td>
            <td>金幣 60,000</td>
          </tr>
          <tr>
            <td>14 局</td>
            <td>金幣 45,000</td>
          </tr>
          <tr>
            <td>11 局</td>
            <td>金幣 30,000</td>
          </tr>
          <tr>
            <td>8 局</td>
            <td>金幣 3,000</td>
          </tr>
          <tr>
            <td>5 局</td>
            <td>金幣 750</td>
          </tr>
          <tr>
            <td className='table-rounded-bl' rowSpan={6}>
              1K~2.9K
            </td>
            <td>17 局以上</td>
            <td>
              <span>每連一局獎金再加</span>
              <br />
              <span>金幣 20,000 [註1]</span>
            </td>
          </tr>
          <tr>
            <td>17 局</td>
            <td>金幣 20,000</td>
          </tr>
          <tr>
            <td>14 局</td>
            <td>金幣 15,000</td>
          </tr>
          <tr>
            <td>11 局</td>
            <td>金幣 10,000</td>
          </tr>
          <tr>
            <td>8 局</td>
            <td>金幣 1,000</td>
          </tr>
          <tr>
            <td>5 局</td>
            <td className='table-rounded-br'>金幣 250</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>
          註1： 若押注區間 300K 以上，連贏 17 局可獲得金幣 6,000,000，接著連贏 18 局可再獲得金幣
          6,000,000，因此當連贏 18 局時，總共可獲得金幣 12,000,000。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>●其它注意事項：</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>連贏紀錄以該局結算的時間點為判斷標準。</li>
        <li>
          僅計算有押注莊或閒的局數，且跳局押注並不影響連贏。
          <br />
          <span style={{ color: '#c5bfbf' }}>
            例：玩家 A 於第 1 局押莊且押中，第 2 局押閒對、幸運六且押中，第 3 局沒有押注，第 4
            局押閒且押中。則玩家即累積連贏 2 局。
          </span>
        </li>
        <li>
          不分桌次、不分廳館，將依據莊/閒注區的押注金額區間計算連贏局數。
          <br />
          <span style={{ color: '#c5bfbf' }}>
            例1：押注 300K 以上為一個區間。當玩家於貴賓 01 桌押莊 300,000；於貴賓 02 桌押閒
            300,000，且兩把押注皆贏，即於該區間累積連贏 2 局。
          </span>
          <br />
          <span style={{ color: '#c5bfbf' }}>
            例2：若玩家於貴賓 02 桌押莊 300,000；於大眾 01 桌押閒
            100,000，且兩把押注皆贏。則分別於押注區間 300K 以上累積連贏 1 局；於押注區間 100K~299.9K
            累積連贏 1 局。
          </span>
        </li>
        <li>
          莊對、閒對、幸運六、和的押注金額，皆不計入連贏所認定的押注金額之中。
          <br />
          <span style={{ color: '#c5bfbf' }}>
            例：玩家押莊 500、押莊對 200、押幸運六 100，則於連贏活動中僅計算該局押莊的金額 500。
          </span>
        </li>
        <li>
          當遇到和局、無效局時，仍不影響連贏局數。
          <br />
          <span style={{ color: '#c5bfbf' }}>
            例：目前已連贏 2 局，此時出現和局，則一樣維持連贏 2 局的紀錄。
          </span>
        </li>
        <li>
          連贏獎金將於斷連後，根據押注金額，依最高連贏局數進行發放。
          <br />
          <span style={{ color: '#c5bfbf' }}>
            例：押注 300K 以上，達成連贏 14 局且已斷連，則可獲得金幣 4,500,000。
          </span>
        </li>
        <li>提醒您，於視訊百家各廳館遊玩，皆以金幣進行押注。</li>
        <li>連贏局數會於活動期間累積計算，但不與下次舉辦的連贏活動合併計算。</li>
        <li>
          當該局下注後 5 分鐘內仍未結算，系統將於 5
          分鐘後自動判斷該局為無效局，且無效局不影響連贏局數。
        </li>
        <li>
          如玩家於活動結束最後一刻遇到無效局，則最晚結算時間為活動結束時間後的 5
          分鐘。於活動結束後的結算期間，進行的下注行為將不計入連贏局數中。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有最終解釋權及終止，調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
