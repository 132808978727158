/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230611/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230611/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity230611/images/title/t3_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/6/22(四)~2023/6/25(日)</span>
        <br />
        <span>每天晚上 21:00~23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動期間內於</span>
        <span style={{ color: '#f5e61c' }}>老虎機大眾廳</span>
        <span>以上廳館進行遊戲，單局贏分達各廳設定的門檻即可選擇 1 個紅包。有機會開出超稀有</span>
        <span style={{ color: '#f5e61c' }}> 8 星鴻運卷軸</span>
        <span>！紅包限時限量提供，敬請把握機會，錯過可惜！</span>
      </p>
      <p>&nbsp;</p>
      <p>※獎勵將以信件形式，在 10 分鐘內寄送至信箱。</p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>各廳館單把贏分門檻：</span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-4 table-radius'>
        <thead>
          <tr>
            <th className='table-rounded-tl table-title-bg-4'>廳館</th>
            <th className='table-rounded-tr table-title-bg-4'>單把贏分門檻</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>貴賓廳</td>
            <td>金幣 350,000</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>大眾廳</td>
            <td className='table-rounded-br'>金幣 50,000</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>每包抽出下述對應廳館其一獎勵：</span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-4 table-radius'>
        <thead>
          <tr>
            <th className='table-rounded-tl table-title-bg-4'>廳館</th>
            <th className=' table-title-bg-4'>最大獎</th>
            <th className='table-rounded-tr table-title-bg-4'>其他獎勵</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>貴賓廳</td>
            <td>
              <span>貴賓廳 8★</span>
              <br />
              <span>鴻運卷軸</span>
              <br />
              <span>每時段 1 位</span>
            </td>
            <td>貴賓廳 5★鴻運卷軸、貴賓廳 5★福運卷軸、貴賓廳 5★招財樹、元寶 300 顆。</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>大眾廳</td>
            <td>
              <span>大眾廳 8★</span>
              <br />
              <span>鴻運卷軸</span>
              <br />
              <span>每時段 2 位</span>
            </td>
            <td className='table-rounded-br'>
              大眾廳 5★鴻運卷軸、大眾廳 5★福運卷軸、大眾廳 5★招財樹、元寶 35 顆。
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>【補充說明】</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          「單把贏分」之計算，將依照遊戲幣入錶底的時機算做1把，因此免費遊戲、吉運祝福、福運祝福、鴻運祝福...等特色，將在特色結束後計算為1把贏分；招財樹與翻倍令牌則每次消耗特色剩餘轉數時算做1把贏分。
        </li>
        <li>使用道具獲得的贏分不計入本次活動，即使達到贏分門檻也無法獲得選紅包機會。</li>
        <li>
          紅包限時限量發完為止。如特色結算時，可搶紅包時段已過或是數量已發送完畢，則無法獲得紅包。舉例：如於活動時間結束前，玩家使用的招財樹尚未將第10把旋轉完成並結算，則就算其中含有一把贏分符合抽紅包門檻，也無法獲得抽紅包資格。
        </li>
        <li>每個時段的紅包獲獎紀錄將於該時段結束或紅包搶完後的 10 分鐘內顯示。</li>
        <li>
          如玩家於該時段結束後 10 分鐘內未選擇紅包，則系統將自動為您選擇並領取未開啟之紅包獎勵。
        </li>
        <li>
          每個時段皆會更換新的一組紅包牆獎勵，若有獎勵未開出，不會將未開獎項之數量延遞至下個時段。
        </li>
        <li>
          如有任何因手機、平板、電腦、網路、電話等之軟硬體技術問題或個人線路不穩、未依遊戲設定完整操作、伺服器維護導致斷線、連接失敗等不可歸責於本公司之事由，而使系統誤送活動訊息或得獎通知，或使參賽玩家所寄出或登錄之資料有延遲、遺失、錯誤、無法辨識或毀損之情況，進而影響活動參與，本公司均不負責，活動照常舉行，不另做補償。
        </li>
        <li>本公司保留更換等值獎品以及變更活動內容或終止活動的權利。</li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有最終解釋權及終止，調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
