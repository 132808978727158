import React, { useEffect, useState } from 'react'
import OfficialWebsiteImage from 'images/navBar/bt_web_m_01.png'
import LineImage from 'images/navBar/bt_line_m_01.png'
import FacebookImage from 'images/navBar/bt_facebook_m_01.png'
import DownloadImage from 'images/navBar/bt_download_m_01.png'
import { v4 as uuid } from 'uuid'
import styles from './navbar.module.sass'
import clsx from 'clsx'
import { OFFICIAL_WEBSITE, LINE, FACEBOOK } from 'constants/link'
import { toDownloadApi } from 'api/system'

const mobileNavbarInfo = [
  {
    id: uuid(),
    image: OfficialWebsiteImage,
    alt: 'gbaoonline',
    link: OFFICIAL_WEBSITE
  },
  {
    id: uuid(),
    image: LineImage,
    alt: 'line',
    link: LINE
  },
  {
    id: uuid(),
    image: FacebookImage,
    alt: 'facebook',
    link: FACEBOOK
  },
  {
    id: uuid(),
    image: DownloadImage,
    alt: 'download'
  }
]

function Navbar() {
  const [downloadUrl, setDownloadUrl] = useState('')

  useEffect(() => {
    const getDownloadUrl = async () => {
      const rdURL = await toDownloadApi()
      setDownloadUrl(rdURL.data)
    }
    getDownloadUrl()
  }, [])

  return (
    <nav className={clsx(styles.root, 'navbarIndex')}>
      <div className={styles.wrapper}>
        {mobileNavbarInfo.map((el) => (
          <a
            className={styles.link}
            key={el.id}
            href={el.alt === 'download' ? downloadUrl : el.link}
            target='_blank'
            rel='noreferrer'
          >
            <div className={clsx(styles.linkContent, 'relative')}>
              <span className='image_obj'>
                <img src={el.image} alt={el.alt} />
              </span>
            </div>
          </a>
        ))}
      </div>
    </nav>
  )
}

export default Navbar
