import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import TopAwardSingle from '../topAwardSingle/TopAwardSingle'
import TopAwardDouble from '../topAwardDouble/TopAwardDouble'
import TopAwardTriple from 'components/topAwardTriple/TopAwardTriple'
import TopAwardDate from 'components/topAwardDate/TopAwardDate'
import { ITopAwardData, ITopAwardDateData } from 'declare/index'
import styles from './topAward.module.sass'

// Gold
import GoldTitle from 'views/activity230911/images/reward/gold_title_m_01.png'
import GoldAwardTitle1 from 'views/activity230911/images/reward/gold_award_1.png'
import GoldAwardTitle2 from 'views/activity230911/images/reward/gold_award_2.png'
import GoldAwardImage1 from 'views/activity230911/images/reward/gold/尊榮賓士卡.png'
import GoldAwardImage2 from 'views/activity230911/images/reward/gold/純金龍牌卡.png'

// Silver
import SilverTitle from 'views/activity230911/images/reward/silver_title_m_01.png'
import SilverAwardTitle1 from 'views/activity230911/images/reward/silver_award_1.png'
import SilverAwardTitle2 from 'views/activity230911/images/reward/silver_award_2.png'
import SilverAwardTitle3 from 'views/activity230911/images/reward/silver_award_3.png'
import SilverAwardTitle4 from 'views/activity230911/images/reward/silver_award_4.png'
import SilverAwardTitle5 from 'views/activity230911/images/reward/silver_award_5.png'
import SilverAwardTitle6 from 'views/activity230911/images/reward/silver_award_6.png'
import SilverAwardTitle7 from 'views/activity230911/images/reward/silver_award_7.png'
import SilverAwardImage1 from 'views/activity230911/images/reward/silver/Gogoro VIVA卡.png'
import SilverAwardImage2 from 'views/activity230911/images/reward/silver/iPhone14卡.png'

// Common
import AwardTitle3 from 'views/activity230911/images/reward/gold_award_3.png'
import AwardTitle4 from 'views/activity230911/images/reward/gold_award_4.png'
import AwardTitle5 from 'views/activity230911/images/reward/gold_award_5.png'
import AwardTitle6 from 'views/activity230911/images/reward/gold_award_6.png'
import AwardTitle7 from 'views/activity230911/images/reward/gold_award_7.png'
import AwardTitle8 from 'views/activity230911/images/reward/gold_award_8.png'
import AwardImagePurple from 'images/award/鴻運卷軸.png'
import AwardImageRed from 'images/award/福運卷軸.png'
import AwardImageTree from 'images/award/招財樹.png'
import AwardImageBowl from 'images/award/元寶(多).png'
import LiveImg from 'views/activity230911/images/reward/live.png'
import AwardDateImg from 'views/activity230911/images/reward/award_date.png'
import AwardDateMobileImg from 'views/activity230911/images/reward/award_date_m.png'
import TopAwardBG01 from 'views/activity230911/images/reward/gold_award_BG_01.png'
import TopAwardBG02 from 'views/activity230911/images/reward/gold_award_BG_02.png'
import TopAwardMobileBG01 from 'views/activity230911/images/reward/gold_award_BG_mob_01.png'
import TopAwardMobileBG02 from 'views/activity230911/images/reward/gold_award_BG_mob_02.png'

const styledTopAwardSingle = {
  contentBackgroundColor: '#85070F',
  contentBorderColor: '#FF973B',
  bannerBorderColor: '#FBDB88',
  bannerBackgroundColor: '#85070F'
}

const goldAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle1,
    awardImg: GoldAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">尊榮賓士卡</span><br class="m:hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG01,
    bgMobileImg: TopAwardMobileBG01
  },
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle2,
    awardImg: GoldAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">純金龍牌卡</span><br class="m:hidden"/> 2位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG02,
    bgMobileImg: TopAwardMobileBG02
  }
]

const goldTripleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle3,
      awardImg: AwardImagePurple,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">尊榮廳4星紫卷</span><br/><br class="m:block hidden"/>每次 1 位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle4,
      awardImg: AwardImageTree,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">尊榮廳4星招財樹</span><br/><br class="m:block hidden"/>每次 1 位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle5,
      awardImg: AwardImagePurple,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星紫卷</span><br/><br class="m:block hidden"/>每次 2 位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      ...styledTopAwardSingle,
      titleImg: AwardTitle6,
      awardImg: AwardImageRed,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星紅卷</span><br/><br class="m:block hidden"/>每次 3 位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle7,
      awardImg: AwardImageTree,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星招財樹</span><br/><br class="m:block hidden"/>每次 5 位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle8,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶 3000 顆</span><br/><br class="m:block hidden"/>每次 15 位</p>'
    }
  ]
]

const silverAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle1,
    awardImg: SilverAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">Gogoro VIVA卡</span><br class="m:hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG01,
    bgMobileImg: TopAwardMobileBG01
  },
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle2,
    awardImg: SilverAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">iPhone14卡</span><br class="m:hidden"/> 2位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <div className='image_obj'>
          <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
        </div>
      </div>
    ),
    bgDesktopImg: TopAwardBG02,
    bgMobileImg: TopAwardMobileBG02
  }
]

const silverDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle3,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶8888顆</span><br/><br class="m:block hidden"/>每次 2 位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle4,
      awardImg: AwardImagePurple,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星紫卷</span><br/><br class="m:block hidden"/>每次 3 位</p>'
    }
  ]
]

const silverTripleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle5,
      awardImg: AwardImageRed,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星紅卷</span><br/><br class="m:block hidden"/>每次 4 位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle6,
      awardImg: AwardImageTree,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星招財樹</span><br/><br class="m:block hidden"/>每次 10 位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle7,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶300顆</span><br/><br class="m:block hidden"/>每次 80 位</p>'
    }
  ]
]

const awardDateData: Array<ITopAwardDateData> = [
  {
    ...styledTopAwardSingle,
    dateDesktopImg: AwardDateImg,
    dateMobileImg: AwardDateMobileImg
  }
]

function TopAward() {
  return (
    <section className={styles.root}>
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={GoldTitle} alt='gold-title' />
        </div>
      </h3>
      {goldAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      {awardDateData.map((el) => (
        <TopAwardDate key={uuid()} {...el} />
      ))}
      {goldTripleData.map((el) => (
        <TopAwardTriple key={uuid()} data={el} />
      ))}
      <p>&nbsp;</p>
      <p>
        <span>● 尊榮賓士卡: 價值 150,000,000 金幣。</span>
        <br />
        <span>● 純金龍牌卡: 價值 6,666,600 金幣。</span>
      </p>
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={SilverTitle} alt='silver-title' />
        </div>
      </h3>
      {silverAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      {awardDateData.map((el) => (
        <TopAwardDate key={uuid()} {...el} />
      ))}
      {silverDoubleData.map((el) => (
        <TopAwardDouble key={uuid()} data={el} />
      ))}
      {silverTripleData.map((el) => (
        <TopAwardTriple key={uuid()} data={el} />
      ))}
      <p>&nbsp;</p>
      <p>
        <span>● Gogoro VIVA卡: 價值 6,998,000 金幣。</span>
        <br />
        <span>● iPhone14卡: 價值 2,790,000 金幣。</span>
      </p>
    </section>
  )
}

export default TopAward
