import Cookies from 'universal-cookie'
const $cookies = new Cookies()

import { isMacOs, isIOS } from 'react-device-detect'

const Get = {
  cookie: (name: string): string | undefined | null => $cookies.get(name),
  platformType: (): string => {
    if (isMacOs || isIOS) {
      return 'apple'
    }

    return 'android'
  }
}

export { Get }
