import React from 'react'
import ActivityDate from 'views/activity240903/images/title/t1.png'
import ActivityMethod from 'views/activity240903/images/title/t2.png'
import ActivityDesignated from 'views/activity240903/images/title/t4.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>第一週 2024/9/5(四) 20:00~2024/9/8(日) 23:59</span>
        <br />
        <span>第二週 2024/9/9(一) 00:00~2024/9/15(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDesignated}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDesignated}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>【素還真】</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>
          <strong>龍榜：</strong>
        </span>
        <br />
        <span>活動期間遊玩指定遊戲，將於</span>
        <span style={{ color: '#f5e61c' }}>每週日</span>
        <span> 24:00 後，依照「主打榜-龍榜」指定廳館</span>
        <span style={{ color: '#f5e61c' }}>累積贏分排名</span>
        <span>
          結算，於指定名次內贈送鴻運卷軸 1 個！若有同分的狀況，則以時間先後順序排名，獎勵將於結算後
          5 分鐘內發送至信箱。
        </span>
        <br />
        <br />
      </p>
      <p>&nbsp;</p>
      <table
        className='table-bg-6 table-radius'
        style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              colSpan={3}
              className='table-rounded-tl table-title-bg-6 table-rounded-tr'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              龍榜獎勵
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              廳館
            </th>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              名次
            </th>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              獎勵
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              尊榮 貴賓廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>尊榮廳 4★鴻運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>貴賓廳 8★鴻運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>貴賓廳 4★鴻運卷軸</td>
          </tr>

          <tr>
            <td rowSpan={5} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              大眾廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 8★鴻運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 4★鴻運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 3★鴻運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 4 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 2★鴻運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 5 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 1★鴻運卷軸</td>
          </tr>
          <tr>
            <td
              rowSpan={4}
              className='table-rounded-bl'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              歡樂廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>歡樂廳 8★鴻運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>歡樂廳 7★鴻運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>歡樂廳 6★鴻運卷軸</td>
          </tr>
          <tr>
            <td
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
              className='table-rounded-br'
            >
              第 4~10 名
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>歡樂廳 5★鴻運卷軸</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>
          <strong>虎榜：</strong>
        </span>
        <br />
        <span>活動期間遊玩指定遊戲，將於</span>
        <span style={{ color: '#f5e61c' }}>每日</span>
        <span> 24:00 後，依照「主打榜-虎榜」指定廳館</span>
        <span style={{ color: '#f5e61c' }}>單把贏分排名</span>
        <span>
          結算，於指定名次內贈送福運卷軸1個！若有同分的狀況，則以時間先後順序排名，獎勵將於結算後5分鐘內發送至信箱。
        </span>
        <br />
        <br />
      </p>
      <p>&nbsp;</p>
      <table
        className='table-bg-6 table-radius'
        style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              colSpan={3}
              className='table-rounded-tl table-title-bg-6 table-rounded-tr'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              虎榜獎勵
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              廳館
            </th>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              名次
            </th>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              獎勵
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              尊榮 貴賓廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>尊榮廳 4★福運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>貴賓廳 8★福運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>貴賓廳 4★福運卷軸</td>
          </tr>

          <tr>
            <td rowSpan={5} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              大眾廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 8★福運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 4★福運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 3★福運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 4 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 2★福運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 5 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳 1★福運卷軸</td>
          </tr>

          <tr>
            <td
              rowSpan={4}
              className='table-rounded-bl'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              歡樂廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>歡樂廳 8★福運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>歡樂廳 7★福運卷軸</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>歡樂廳 6★福運卷軸</td>
          </tr>
          <tr>
            <td
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
              className='table-rounded-br'
            >
              第 4~10 名
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>歡樂廳 5★福運卷軸</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>【注意事項】</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>歡樂廳將統一以金幣為單位顯示排行。100銀幣贏分換算成1金幣，不顯示小數點。 </li>
        <li>
          「單把倍數」之計算，將依照遊戲幣入錶底的時機算做1把，因此吉運卷軸、福運卷軸、鴻運卷軸將在道具使用結束後計算為一把；招財樹及翻倍令牌則每次消耗特色剩餘轉數時，都算做1把。{' '}
        </li>
        <li>
          如該把遊戲行為結算時，超出活動截止時間，則該把遊戲行為不計入活動。起因於連線狀況不穩，導致的結算時間延遲，將以伺服器之電磁紀錄為準進行獎勵結算。{' '}
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
