/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230321/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230321/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity230321/images/title/t3_pc_01.png'

import GoldVoucher from 'images/voucher/gold_voucher.png'
import SilverVoucher from 'images/voucher/silver_voucher.png'
import Coin from 'images/common/coin_gold_01.png'
import TopAward from '../../topAward/TopAward'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/3/22(三) 12:00~2023/4/18(二) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間，玩家於全平台各遊戲大眾廳與貴賓廳獲得的贏分，經活動加乘倍數後，轉換成該廳券別的分數，分數累積達門檻即可獲得一張抽獎券，獎券依據不同廳分為銀、金兩種。
        </span>
        <br />
        <span>
          於活動結束後，將進行各券別抽獎，億級特斯拉獎/金犀墜飾獎/Gogoro
          VIVA獎/iPhone14獎將於4/19(三)由官方直播抽出，其餘獎項於4/21前由系統隨機抽出，所有廳別同一個獎項玩家皆可重複獲得，持有抽獎券數量越多，有機會一次抱走多項好禮！
        </span>
        <br />
        <br />
        <span style={{ color: '#f5e61c' }}>※若重複抽出該券別已中過獎的號碼，不可重複中獎。</span>
        <br />
        <br />
        <span style={{ color: '#f5e61c' }}>直播時間：</span>
        <span>2023/4/19(三) 晚上19:00</span>
        <br />
        <br />
        <span style={{ color: '#f5e61c' }}>直播平台：</span>
        <a href='https://www.facebook.com/gbaoonline' target='_blank' rel='noreferrer'>
          聚寶Online官方粉絲專頁
        </a>
        <br />
        <br />
        <span style={{ color: '#f5e61c' }}>獲獎名單公布時間：</span>
        <br />
        <span>將於2023/4/21(五) 18:00前公布於聚寶Online官方網站</span>
        <br />
        <br />
        <span style={{ color: '#f5e61c' }}>獎項派獎時間：</span>
        <br />
        <span>
          『萬獸鴻運獎』、『萬獸福運獎』、『萬獸招財獎』、『開運元寶獎』將於2023/4/21(五)
          18:00前寄至遊戲內信箱，其餘獎項將於2023/5/12(五) 18:00前寄出。
        </span>
        <br />
        <span>
          『億級特斯拉獎』、『金犀墜飾獎』、『Gogoro
          VIVA獎』、『iPhone14獎』獲獎玩家需主動聯繫客服索取領獎確認單，且在2023/5/10 (三)
          23:59前填寫完畢，透過傳真或電子信箱回傳給客服並核資完成，獎項將在2023/5/12(五) 18:00
          前寄出。
        </span>
        <br />
        <br />
        <span>活動期間等級門檻開放：</span>
        <br />
        <span>
          於2023/3/22(三)維護後~2023/4/26(三)維護前，限時將老虎機貴賓廳的等級門檻從100等降低至20等(VIP層級門檻不變)。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>贏分門檻及乘倍說明：</span>
      </p>
      <p className='m:hidden'>&nbsp;</p>
      <table className='m:hidden table-bg-3 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-3 table-rounded-tl'>獲得金券積分門檻</th>
            <th className='table-title-bg-3 table-rounded-tr'>獲得銀券積分門檻</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='table-rounded-bl'>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                200,000/張
              </span>
            </td>
            <td className='table-rounded-br'>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                25,000/張
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p className='hidden m:block'>&nbsp;</p>
      <table className='hidden m:table table-bg-3 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-3 table-rounded-tl table-rounded-tr'>獲得金券積分門檻</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='table-rounded-bl table-rounded-br'>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  style={{ width: '40px', marginRight: '1rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                200,000/張
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='hidden m:table table-bg-3 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-3 table-rounded-tl table-rounded-tr'>獲得銀券積分門檻</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='table-rounded-bl table-rounded-br'>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  style={{ width: '40px', marginRight: '1rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                25,000/張
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p className='hidden m:block'>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>各遊戲，各廳對應的券別以及贏分乘倍：</span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-3 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-3 table-rounded-tl'>遊戲項目</th>
            <th className='table-title-bg-3'>貴賓廳贏分乘倍</th>
            <th className='table-title-bg-3 table-rounded-tr'>大眾廳贏分乘倍</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>老虎機</td>
            <td>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '40px', marginRight: '0.5rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                1倍
              </span>
            </td>
            <td>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '40px', marginRight: '0.5rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                1倍
              </span>
            </td>
          </tr>
          <tr>
            <td>視訊百家</td>
            <td>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '40px', marginRight: '0.5rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                0.5倍
              </span>
            </td>
            <td>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '40px', marginRight: '0.5rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                0.5倍
              </span>
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>經典 5PK</td>
            <td>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '40px', marginRight: '0.5rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                1.5倍
              </span>
            </td>
            <td className='table-rounded-br'>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '40px', marginRight: '0.5rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                1.5倍
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-3 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-3 table-rounded-tl'>遊戲項目</th>
            <th className='table-title-bg-3'>
              面額
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              50K以上(含)贏分乘倍
            </th>
            <th className='table-title-bg-3 table-rounded-tr'>
              面額小於
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              50K贏分乘倍
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='table-rounded-bl'>刮刮卡</td>
            <td>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '40px', marginRight: '0.5rem' }}
                  src={GoldVoucher}
                  alt='gold-voucher'
                />
                2倍
              </span>
            </td>
            <td className='table-rounded-br'>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '60px', marginRight: '1rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '40px', marginRight: '0.5rem' }}
                  src={SilverVoucher}
                  alt='silver-voucher'
                />
                2倍
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>範例：</span>
      </p>
      <ol>
        <li>
          小花於活動期間在老虎機大眾廳獲得贏分2,500分，依照該廳贏分乘倍為1，當局可獲得2,500x1=2,500積分，因未達銀券門檻故無法獲得銀券。
        </li>
        <li>
          小雲於活動期間在視訊百家貴賓廳獲得贏分500,000分，依照該廳贏分乘倍為0.5，當局可獲得500,000x0.5=250,000積分，因超過金券門檻故可獲得1張金券。
        </li>
        <li>
          小海於活動期間購買面額50K的刮刮卡獲得贏分250,000分，依照該廳贏分乘倍為2，當局可獲得250,000x2=500,000積分，因超過金券門檻2倍，故可獲得2張金券。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <TopAward />
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>【注意事項】</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          億級特斯拉獎/金犀墜飾獎/Gogoro VIVA獎/iPhone14 獎，獲獎玩家最晚需於 2023/5/10(三) 23:59
          前與客服確認其中獎資格及提供領獎確認單由客服致電核資完成，如未於該指定期間完成上述事項則視同放棄。
        </li>
        <li>
          本公司有權檢視各參賽玩家之活動參與行為及得獎情形是否涉嫌違反遊戲規章或公平原則，例：人為操作、蓄意偽造、使用不實或虛偽身份、多開（重）帳號、短時間異常多筆參與行為、透過任何電腦程式參與活動、詐欺、任何違反本遊戲服務條款或遊戲規章之情事者，或以任何其他破壞公平性之不正當的方式參與活動。參賽玩家因上述情形所獲得之活動資格及獎項，本公司得一概取消之。
        </li>
        <li>
          如有任何因手機、平板、電腦、網路、電話等之軟硬體技術問題或個人線路不穩、未依遊戲設定完整操作、伺服器維護導致斷線、連接失敗等不可歸責於本公司之事由，而使系統誤送活動訊息或得獎通知，或使參賽玩家所寄出或登錄之資料有延遲、遺失、錯誤、無法辨識或毀損之情況，進而影響活動參與，本公司均不負責，活動照常舉行，不另做補償。
        </li>
        <li>
          參賽玩家若於活動獎項贈出前違反任何服務條款或遊戲規章經官方認定屬實，將失去參與活動/領獎之資格。開獎時若抽出上述違規玩家獎券號碼，則視為無效券號碼，獎項保留續抽；如得獎後始違規者，其抽中之獎項一律取消，不得異議。
        </li>
        <li>
          根據中華民國稅法規定，本活動獎項須納入得獎玩家年度所得報繳所得稅。若獎項所得總額超過新台幣貳萬元，應由本公司依法辦理扣繳
          10% 所得稅；中獎玩家如非屬非我國境內之居住人，則應由本公司依法辦理扣繳 20%
          所得稅。上述應辦理扣繳之得獎玩家須繳交身分證明文件正反面影本予本公司；若不願意配合者，則視為自動棄權，喪失領獎資格。
        </li>
        <li>
          玩家若獲得活動獎項，本公司只提供該活動獎項；獎項不包含額外產生之費用(如:稅金等項目)。
        </li>
        <li>
          申請領獎之玩家未滿 20
          歲者，需附上申請人及法定代理人的新式身分證正反面影本，以進行驗證。若您尚未申辦身份證，請用戶口名簿影本取代身份證。
        </li>
        <li>所有活動中獎名單以官方公告為主。</li>
        <li>
          參賽玩家需保證所有填寫或提出之資料均為真實且正確，並未冒用或盜用他人資料。如有不實或不正確資訊，本公司將取消其中獎資格，並得就其損害主辦單位或任何第三人權益之事，提出一切民、刑事訴追。
        </li>
        <li>
          參賽玩家視為同意本公司於本活動目的及期限內蒐集、處理及利用您的個人資料，若您不提供、提供不完整或不正確的資料將使您無法參加本活動。若您日後不同意本公司處理及利用您的個人資料或您得獎後不願意為完成本贈獎處理相關事宜而另行提供其他必要（例如稅法之相關規定）之個人資料（姓名、身分證統一編號、地址、聯絡電話等），將使您失去獲獎資格。
        </li>
        <li>
          本活動發給之獎勵/獎項為機會中獎商品，消費者購買遊戲產品或參加活動不代表即可獲得特定商品。
        </li>
        <li>
          參賽玩家於參加本活動之同時，即同意接受本活動之活動辦法與注意事項之規範，如不同意或有違反，應視為無參加資格，或本公司得取消其得獎資格，如因此有致生損害於本公司或其他任何第三人，本公司得向參賽玩家請求損害賠償，參賽玩家應負一切相關責任。
        </li>
        <li>本公司保留更換等值獎品以及變更活動內容或終止活動的權利。</li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
