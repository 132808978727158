import { Route, Routes } from 'react-router-dom'
import styles from './activity240120.module.sass'
import variable from './styles/variable.module.sass'
import Header from './components/header/Header'
import NavGroup from 'components/navGroup/NavGroup'
import { INavCard } from 'components/navCard/NavCard'
import DownloadGame from 'components/downloadGame/DownloadGame'
import GoTop from 'components/goTop/GoTop'
import ActivityContainer from 'components/activity/activityContainer/ActivityContainer'
import { Format } from 'utils/formula'

import activityBtnBT1Default from 'views/activity240120/images/button/navGroup/default/bt_pc_01.png'
import activityBtnBT2Default from 'views/activity240120/images/button/navGroup/default/bt_pc_02.png'
import activityBtnBT3Default from 'views/activity240120/images/button/navGroup/default/bt_pc_03.png'
import activityBtnBT4Default from 'views/activity240120/images/button/navGroup/default/bt_pc_04.png'
import activityBtnBT5Default from 'views/activity240120/images/button/navGroup/default/bt_pc_05.png'
import activityBtnBT6Default from 'views/activity240120/images/button/navGroup/default/bt_pc_06.png'
import activityBtnBT7Default from 'views/activity240120/images/button/navGroup/default/bt_pc_07.png'
import activityBtnBT8Default from 'views/activity240120/images/button/navGroup/default/bt_pc_08.png'
import activityBtnBT9Default from 'views/activity240120/images/button/navGroup/default/bt_pc_09.png'
import activityBtnBT10Default from 'views/activity240120/images/button/navGroup/default/bt_pc_10.png'
import activityBtnBT1Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_01.png'
import activityBtnBT2Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_02.png'
import activityBtnBT3Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_03.png'
import activityBtnBT4Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_04.png'
import activityBtnBT5Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_05.png'
import activityBtnBT6Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_06.png'
import activityBtnBT7Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_07.png'
import activityBtnBT8Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_08.png'
import activityBtnBT9Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_09.png'
import activityBtnBT10Hover from 'views/activity240120/images/button/navGroup/hover/bt_pc_10.png'
import activityBtnBT1Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_01.png'
import activityBtnBT2Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_02.png'
import activityBtnBT3Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_03.png'
import activityBtnBT4Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_04.png'
import activityBtnBT5Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_05.png'
import activityBtnBT6Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_06.png'
import activityBtnBT7Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_07.png'
import activityBtnBT8Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_08.png'
import activityBtnBT9Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_09.png'
import activityBtnBT10Lock from 'views/activity240120/images/button/navGroup/lock/bt_pc_10.png'
import activityContentTitle01 from 'views/activity240120/images/pageHeader/bt_pc_01.png'
import activityContentTitle02 from 'views/activity240120/images/pageHeader/bt_pc_02.png'
import activityContentTitle03 from 'views/activity240120/images/pageHeader/bt_pc_03.png'
import activityContentTitle04 from 'views/activity240120/images/pageHeader/bt_pc_04.png'
import activityContentTitle05 from 'views/activity240120/images/pageHeader/bt_pc_05.png'
import activityContentTitle06 from 'views/activity240120/images/pageHeader/bt_pc_06.png'
import activityContentTitle07 from 'views/activity240120/images/pageHeader/bt_pc_07.png'
import activityContentTitle08 from 'views/activity240120/images/pageHeader/bt_pc_08.png'
import activityContentTitle09 from 'views/activity240120/images/pageHeader/bt_pc_09.png'
import activityContentTitle10 from 'views/activity240120/images/pageHeader/bt_pc_10.png'
import Activity1 from 'views/activity240120/components/activityContentData/1/Activity'
import Activity2 from 'views/activity240120/components/activityContentData/2/Activity'
import Activity3 from 'views/activity240120/components/activityContentData/3/Activity'
import Activity4 from 'views/activity240120/components/activityContentData/4/Activity'
import Activity5 from 'views/activity240120/components/activityContentData/5/Activity'
import Activity6 from 'views/activity240120/components/activityContentData/6/Activity'
import Activity7 from 'views/activity240120/components/activityContentData/7/Activity'
import Activity8 from 'views/activity240120/components/activityContentData/8/Activity'
import Activity9 from 'views/activity240120/components/activityContentData/9/Activity'
import Activity10 from 'views/activity240120/components/activityContentData/10/Activity'

const TITLE = '聚寶ONLINE-龍馬奔騰賀新春'

const navGroupData: Array<INavCard> = [
  {
    buttonStatus: {
      imgDefault: activityBtnBT1Default,
      imgHover: activityBtnBT1Hover,
      imgLock: activityBtnBT1Lock
    },
    altForImage: '奧迪名車賞',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 1, 21, 12, 0, 0),
    link: '/activity240120/1#target',
    imgTitle: activityContentTitle01,
    component: <Activity1 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT2Default,
      imgHover: activityBtnBT2Hover,
      imgLock: activityBtnBT2Lock
    },
    altForImage: '贏戰龍意發',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 1, 21, 12, 0, 0),
    link: '/activity240120/2#target',
    imgTitle: activityContentTitle02,
    component: <Activity2 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT3Default,
      imgHover: activityBtnBT3Hover,
      imgLock: activityBtnBT3Lock
    },
    altForImage: '金爆隔日券',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 1, 21, 12, 0, 0),
    link: '/activity240120/3#target',
    imgTitle: activityContentTitle03,
    component: <Activity3 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT4Default,
      imgHover: activityBtnBT4Hover,
      imgLock: activityBtnBT4Lock
    },
    altForImage: '賀歲刮財運',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 1, 21, 12, 0, 0),
    link: '/activity240120/4#target',
    imgTitle: activityContentTitle04,
    component: <Activity4 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT5Default,
      imgHover: activityBtnBT5Hover,
      imgLock: activityBtnBT5Lock
    },
    altForImage: '春日挖寶遊',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 1, 28, 12, 0, 0),
    link: '/activity240120/5#target',
    imgTitle: activityContentTitle05,
    component: <Activity5 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT6Default,
      imgHover: activityBtnBT6Hover,
      imgLock: activityBtnBT6Lock
    },
    altForImage: '衝榜龍喜吉',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 2, 4, 12, 0, 0),
    link: '/activity240120/6#target',
    imgTitle: activityContentTitle06,
    component: <Activity6 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT7Default,
      imgHover: activityBtnBT7Hover,
      imgLock: activityBtnBT7Lock
    },
    altForImage: '綜藝幸運星',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 2, 4, 12, 0, 0),
    link: '/activity240120/7#target',
    imgTitle: activityContentTitle07,
    component: <Activity7 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT8Default,
      imgHover: activityBtnBT8Hover,
      imgLock: activityBtnBT8Lock
    },
    altForImage: '釣魚發燒友',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 2, 7, 12, 0, 0),
    link: '/activity240120/8#target',
    imgTitle: activityContentTitle08,
    component: <Activity8 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT9Default,
      imgHover: activityBtnBT9Hover,
      imgLock: activityBtnBT9Lock
    },
    altForImage: '紅包喜連來',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 2, 8, 12, 0, 0),
    link: '/activity240120/9#target',
    imgTitle: activityContentTitle09,
    component: <Activity9 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT10Default,
      imgHover: activityBtnBT10Hover,
      imgLock: activityBtnBT10Lock
    },
    altForImage: '富貴滿福樹',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 2, 11, 12, 0, 0),
    link: '/activity240120/10#target',
    imgTitle: activityContentTitle10,
    component: <Activity10 />
  }
]

function Activity() {
  document.title = TITLE

  return (
    <div className={styles.root}>
      <div className={styles.background}></div>
      <DownloadGame zIndex={variable.zDownloadGame} colorTheme='newYearPrimary' />
      <Header />
      <GoTop zIndex={variable.zGoTop} colorFilter='hue-rotate(15deg)' />
      <section className={styles.navGroup}>
        <NavGroup datas={navGroupData} />
      </section>
      <div id='target'>
        <Routes>
          <Route
            path='/:id'
            element={<ActivityContainer activityData={navGroupData} colorTheme='newYearPrimary' />}
          />
          <Route
            index
            element={<ActivityContainer activityData={navGroupData} colorTheme='newYearPrimary' />}
          />
        </Routes>
      </div>
    </div>
  )
}

export default Activity
