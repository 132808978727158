import React from 'react'
import ActivityDate from 'views/activity240120/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity240120/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity240120/images/title/t3_pc_01.png'
import ActivityDeadline from 'views/activity240120/images/title/t5_pc_01.png'
import KeyRainbow from 'images/key/key_rainbow.png'
import KeyGolden from 'images/key/key_gold.png'
import KeySilver from 'images/key/key_silver.png'
import KeyCopper from 'images/key/key_copper.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2024/1/29(一) 10:00~2024/2/4(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDeadline}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDeadline}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動開始~2024/2/7(三) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動期間玩家可於「春日挖寶遊」活動頁面，依廳館使用獲得的寶藏鑰匙進行：</span>
      </p>
      <br />
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          升級藏寶點：升級藏寶點可提升挖掘寶物時獲得的道具星等。強化到最高等級保證獲得該廳館 7~8
          星道具或元寶。每次升級藏寶點所需的寶藏鑰匙數量會隨升級次數變動。
        </li>
        <li>
          挖掘寶物：根據當下的藏寶點等級進行挖掘並獲得獎勵，藏寶點等級將於每次挖掘寶物後進行重置。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>※ 歡樂廳每日可挖掘寶物次數為一次。</span>
        <br />
        <span style={{ color: '#f5e61c' }}>※ 藏寶點等級越高更有機會找到紅紫卷！</span>
        <br />
        <span>
          ※ 範例：小明從尊榮廳老虎機累積獲得 18 個彩虹鑰匙，此時小明若先消耗 8
          個彩虹鑰匙「升級藏寶點」，將藏寶點升級至 LV2。接著選擇消耗 10
          個彩虹鑰匙「挖掘寶物」並獲得獎勵後，藏寶點等級會重置為 LV1。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              尊榮廳
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-5'>藏寶點等級</th>
            <th className='table-title-bg-5'>可獲得獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>LV 4</td>
            <td>
              7～8★ 道具
              <br />或 6,000 個元寶
            </td>
          </tr>
          <tr>
            <td>LV 3</td>
            <td>
              5～8★ 道具
              <br />或 3,000 個元寶
            </td>
          </tr>
          <tr>
            <td>LV 2</td>
            <td>
              3～8★ 道具
              <br />或 2,000 個元寶
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>LV 1</td>
            <td className='table-rounded-br'>
              1～8★ 道具
              <br />或 1,500 個元寶
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              貴賓廳
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-5'>藏寶點等級</th>
            <th className='table-title-bg-5'>可獲得獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>LV 4</td>
            <td>
              7～8★ 道具
              <br />或 1,300 個元寶
            </td>
          </tr>
          <tr>
            <td>LV 3</td>
            <td>
              5～8★ 道具
              <br />或 850 個元寶
            </td>
          </tr>
          <tr>
            <td>LV 2</td>
            <td>
              3～8★ 道具
              <br />或 500 個元寶
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>LV 1</td>
            <td className='table-rounded-br'>
              1～8★ 道具
              <br />或 350 個元寶
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              大眾廳
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-5'>藏寶點等級</th>
            <th className='table-title-bg-5'>可獲得獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>LV 4</td>
            <td>
              7～8★ 道具
              <br />或 200 個元寶
            </td>
          </tr>
          <tr>
            <td>LV 3</td>
            <td>
              5～8★ 道具
              <br />或 100 個元寶
            </td>
          </tr>
          <tr>
            <td>LV 2</td>
            <td>
              3～8★ 道具
              <br />或 50 個元寶
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>LV 1</td>
            <td className='table-rounded-br'>
              1～8★ 道具
              <br />或 30 個元寶
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              歡樂廳
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-5'>藏寶點等級</th>
            <th className='table-title-bg-5'>可獲得獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>LV 4</td>
            <td>
              7～8★ 道具
              <br />或 5 個元寶
            </td>
          </tr>
          <tr>
            <td>LV 3</td>
            <td>
              5～8★ 道具
              <br />或 3 個元寶
            </td>
          </tr>
          <tr>
            <td>LV 2</td>
            <td>
              3～8★ 道具
              <br />或 2 個元寶
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>LV 1</td>
            <td className='table-rounded-br'>
              1～8★ 道具
              <br />或 1 個元寶
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span>※ 寶藏鑰匙獲取方法：</span>
        <br />
        <span>
          玩家於各廳館老虎機押注，每把皆有機會獲得寶藏鑰匙。押注越高，寶藏鑰匙的掉落機率越高！且依廳館可獲得不同顏色的寶藏鑰匙。
          若想蒐集更多寶藏鑰匙，也可前往活動頁面購買【花開迎福禮包】，內含額外加贈的寶藏鑰匙！
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th className='table-rounded-tl table-title-bg-5'>廳館</th>
            <th className='table-rounded-tr table-title-bg-5'>可獲得寶藏鑰匙</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>尊榮廳</td>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img style={{ width: '60px', marginRight: '1rem' }} src={KeyRainbow} alt='gold-key' />
              彩虹鑰匙
            </td>
          </tr>
          <tr>
            <td>貴賓廳</td>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img style={{ width: '60px', marginRight: '1rem' }} src={KeyGolden} alt='gold-key' />
              金鑰匙
            </td>
          </tr>
          <tr>
            <td>大眾廳</td>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                style={{ width: '60px', marginRight: '1rem' }}
                src={KeySilver}
                alt='gold-silver'
              />
              銀鑰匙
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>歡樂廳</td>
            <td
              className='table-rounded-br'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                style={{ width: '60px', marginRight: '1rem' }}
                src={KeyCopper}
                alt='gold-copper'
              />
              銅鑰匙
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span>
          活動結束後，活動介面會保留三天以便玩家兌換獎勵，但期間遊玩遊戲無法再獲得寶藏鑰匙，保留天數過後，活動材料將會由系統回收。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>【注意事項】</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          部分優惠商品每個遊戲帳號有限制購買次數，成功購買後將無法更換。
          <br />※
          特別提醒您，官網公告之首儲優惠活動每個遊戲帳號僅限購一次，購買其他任意商品(不論平台方)都將永久失去超值首儲方案的購買資格。
        </li>
        <li>部分優惠商品為限時販售，活動時間判定以 GMT+8 為主，成功購買後將無法更換。</li>
        <li>部分優惠商品為機會中獎商品，消費者購買或參與活動不代表即可 獲得特定商品。</li>
        <li>
          限時限次活動商品以「收款平台方收據上的購買時間」判斷是否成功以優惠內容購買(舉例：安卓、iOS提供收據上的付款時間)，因此若發生付款失敗、交易取消、延遲到帳等收款平台方之狀況，該筆交易將無法獲得活動商品優惠內容，系統將自動以付款金額
          1:1 比值給予對應點數發送。
        </li>
        <li>
          由於依照平台方提供收據上的紀錄時間作為判斷依據來扣除限購次數，故當您點選購買後，如平台方發生結帳失敗、延遲發送收據或其他緣由造成未完成結帳，品項不分儲值管道均維持顯示限購優惠相關資訊(包含首儲亦適用此情形)。
        </li>
        <li>
          當購買複數個顯示限制購買次數之商品時(含首儲)，於結帳後將依照各項儲值管道處理之流程及速度，由系統自動判定，僅有符合限購次數之商品可獲得優惠，其他品項將依照付款金額
          1:1 比值給予對應點數。
        </li>
        <li>
          若玩家同時於不同管道儲值繳費，有小機率發生因系統延遲，而導致最後獲得優惠商品非第一筆交易之商品。
        </li>
        <li>
          建議玩家進行儲值時，先購買單一品項並結帳，於確認收到購買商品後再進行第二筆儲值或是切換帳號行為，以確保最終獲得商品與您認知一致。
        </li>
        <li>
          儲值優惠內容以聚寶 Online
          官方網站公告為主。如因故導致無法執行時，本公司有權修改、暫停或停止儲值優惠之所有相關內容。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
