import React from 'react'
import styles from './commonAward.module.sass'
import clsx from 'clsx'

type infoSize = 'small' | 'medium' | 'large'

interface AwardInfo {
  hallName: string
  imageUrl: string
  productName: string
  productCount: string
  otherAward: string
}

interface ITopAwardSingle {
  infoSize?: infoSize
  data?: AwardInfo[]
  disabledInfo?: boolean
  disabledOtherAwardTitle?: boolean
  style?: {
    bgColor?: string
    titleBgColor?: string
    otherBgColor?: string
  }
}

function CommonAward(props: ITopAwardSingle) {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {props.data.map((item: AwardInfo) => (
          <div
            key={item.hallName}
            className={styles.frame}
            style={{
              backgroundColor: props.style?.bgColor
            }}
          >
            <h3
              className={styles.title}
              style={{
                backgroundColor: props.style?.titleBgColor
              }}
            >
              {item.hallName}
            </h3>
            {!props.disabledInfo && (
              <div
                className={clsx(
                  styles.infoWrapper,
                  props.infoSize === 'small' && styles.small,
                  props.infoSize === 'medium' && styles.medium,
                  props.infoSize === 'large' && styles.large
                )}
              >
                <div className={styles.awardImage}>
                  <img src={item.imageUrl} alt='award-card' />
                </div>
                <p className={styles.productName}>
                  <strong>
                    <span dangerouslySetInnerHTML={{ __html: item.productName }} />
                  </strong>
                </p>
                <p className={styles.productCount}>{item.productCount}</p>
              </div>
            )}
            {!props.disabledOtherAwardTitle && (
              <p
                className={styles.otherAwardTitle}
                style={{
                  backgroundColor: props.style?.otherBgColor
                }}
              >
                <strong>其他獎勵</strong>
              </p>
            )}
            <p className={styles.otherAwardText}>{item.otherAward}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CommonAward
