import React from 'react'
import ActivityDate from 'views/activity240903/images/title/t1.png'
import ActivityRedemptionPeriod from 'views/activity240903/images/title/t5.png'
import ActivityDesignated from 'views/activity240903/images/title/t4.png'
import ActivityMethod from 'views/activity240903/images/title/t2.png'
import ActivityDiscount from 'views/activity240903/images/title/t7.png'
import ActivityCardPoolDescription from 'views/activity240903/images/title/t6.png'
import AwardImageBullion from 'images/award/禮金卡_一萬金塊_.png'
import Coin from 'images/common/coin_gold_01.png'
import RewardHeadFrameSword from 'views/activity240903/images/reward/HeadPhotoFrame/1.png'
import RewardHeadFrameFan from 'views/activity240903/images/reward/HeadPhotoFrame/2.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2024/9/4(三) 12:00~2024/10/1(二) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityRedemptionPeriod}
          alt='activity-redemption-period'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityRedemptionPeriod}
          alt='activity-redemption-period'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        活動開始~ 2024/10/4(五) 23:59
        <br />
        <br />※ 兌換期限過後，如活動材料未使用完畢則將會由系統回收。
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        「獨家老虎機」、「特殊老虎機」、「捕魚機」、所有刮刮卡、【錢樹推幣機】、【馬戲團推幣機】、【向錢衝】、【餐廳賓果&lt;黃金版&gt;】、【5PK】、【3PK小遊戲】、【極速539小遊戲】。
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間內於指定遊戲遊玩，累積押碼達標可獲得1顆玉石。使用玉石召喚獲得霹靂聯名卡片、金幣或元寶。
        </span>
        <br />
        <br />
        <span>獲得卡片時可以進行：</span>
        <ol>
          <li>
            兌換獎勵：前往活動頁點擊獎勵兌換進行兌換，越高星等的卡片獎勵越豐沃！大獎狂送一萬金塊禮金卡，價值1,000,000金幣！
          </li>
          <li>
            解鎖圖鑑：全新圖鑑系統！召喚2星～5星卡片可解鎖圖鑑並納入永久收藏！4星全蒐集、5星全蒐集，還會贈送專屬紀念頭像框！
          </li>
        </ol>
        <p>&nbsp;</p>
        <table
          className='table-bg-6 table-radius'
          style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
        >
          <thead>
            <tr>
              <th
                className=' table-title-bg-6 '
                style={{
                  border: '1px solid #00B6DE',
                  borderRadius: '2px',
                  color: '#ffde9e',
                  fontWeight: 'bold'
                }}
              >
                最大獎
              </th>
              <th
                className=' table-title-bg-6 '
                style={{
                  border: '1px solid #00B6DE',
                  borderRadius: '2px',
                  color: '#ffde9e',
                  fontWeight: 'bold'
                }}
              >
                5星全蒐集
              </th>
              <th
                className=' table-title-bg-6 '
                style={{
                  border: '1px solid #00B6DE',
                  borderRadius: '2px',
                  color: '#ffde9e',
                  fontWeight: 'bold'
                }}
              >
                4星全蒐集
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
                <div>
                  <img src={AwardImageBullion} alt='activity-award-bullion' width={'80%'} />
                </div>
              </td>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
                <div>
                  <img src={RewardHeadFrameSword} alt='activity-award-sword' width={'80%'} />
                </div>
              </td>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
                <div>
                  <img src={RewardHeadFrameFan} alt='activity-award-fan' width={'80%'} />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: '1px solid #00B6DE',
                  borderRadius: '2px',
                  padding: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '0.5rem',
                      flexWrap: 'wrap'
                    }}
                  >
                    <img src={Coin} alt='coin' width={'30px'} />
                    <span>1,000,000</span>
                  </div>
                </div>
              </td>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
                <p>劍氣狂刀頭像框</p>
              </td>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
                <p>仙韻拂塵頭像框</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <p>
          <img
            className='m:hidden'
            style={{ width: '303px' }}
            src={ActivityCardPoolDescription}
            alt='activity-card-pool-description'
          />
          <img
            className='hidden m:block'
            style={{ width: '240px', margin: '0 auto' }}
            src={ActivityCardPoolDescription}
            alt='activity-card-pool-description'
          />
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>高級卡池：更容易召喚出高星卡片，且超稀有發財聚寶卡、滿福聚寶卡僅在高級卡池出現！</li>
          <li>限定卡池：活動期間將每日精選角色，提升該角色的召喚機率！</li>
        </ol>
        <p>&nbsp;</p>
        <table
          className='table-bg-6 table-radius'
          style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
        >
          <thead>
            <tr>
              <th
                className=' table-title-bg-6 '
                style={{
                  border: '1px solid #00B6DE',
                  borderRadius: '2px',
                  color: '#ffde9e',
                  fontWeight: 'bold'
                }}
              >
                開放時間
              </th>
              <th
                className=' table-title-bg-6 '
                style={{
                  border: '1px solid #00B6DE',
                  borderRadius: '2px',
                  color: '#ffde9e',
                  fontWeight: 'bold'
                }}
              >
                限定角色
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>週一、週四</td>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
                魔化葉小釵、秦假仙
              </td>
            </tr>
            <tr>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>週二、週五</td>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>棄天帝、亂世狂刀</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>週三、週六、週日</td>
              <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
                素還真、戰甲素還真
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <p>
          <img
            className='m:hidden'
            style={{ width: '303px' }}
            src={ActivityDiscount}
            alt='activity-discount'
          />
          <img
            className='hidden m:block'
            style={{ width: '240px', margin: '0 auto' }}
            src={ActivityDiscount}
            alt='activity-discount'
          />
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>玉石藏寶庫：若想蒐集更多玉石，也可前往活動頁面購買限量商品，內含額外加贈的玉石！</li>
          <li>超值召喚：高星卡片立即入手！眾多好禮換不停！限定優惠別錯過！</li>
        </ol>
        <p>&nbsp;</p>

        <p>
          <span style={{ color: '#ffde9e' }}>【注意事項】</span>
        </p>
        <ol
          style={{
            textAlign: 'justify'
          }}
        >
          <li>
            活動結束後，活動介面會保留3天，在此期間玩家仍可進行召喚抽卡、兌換獎勵，但遊玩指定遊戲已無法再獲得玉石。
          </li>
          <li>活動結束3天後，剩餘玉石 、卡片將全數由系統回收。</li>
          <li>玉石藏寶庫購買相關內容請見遊戲內活動介面之說明。</li>
          <li>
            如該把遊戲行為結算時，超出活動截止時間，則該把遊戲行為不計入活動。起因於連線狀況不穩，導致的結算時間延遲，將以伺服器之電磁紀錄為準進行獎勵結算。
          </li>
        </ol>
        <p>&nbsp;</p>

        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
