import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import deviceSizeVar from 'theme/common/variables.module.sass'
import useNavCardSelectStore from '../../zustand/navCardSelectStore/index'
import styles from './navCard.module.sass'

export interface INavCardButtonStatus {
  imgDefault: string
  imgHover: string
  imgLock: string
}

export interface INavCard {
  buttonStatus: INavCardButtonStatus
  altForImage: string
  isBlock: boolean
  link: string
  imgTitle: string
  component?: JSX.Element
  index?: number
}

function NavCard(props: INavCard) {
  const { index, altForImage, buttonStatus, isBlock, link } = props
  const { imgDefault, imgHover, imgLock } = buttonStatus
  const { curActivityNavCardIndex } = useNavCardSelectStore()

  const onLinkClick = useCallback((e: React.FormEvent<HTMLAnchorElement>, block: boolean) => {
    if (block) {
      e.preventDefault()
    }
  }, [])

  return (
    <NavLink
      className={clsx(
        styles.root,
        isBlock && styles.notAllowed,
        index === curActivityNavCardIndex - 1 && styles.isActive
      )}
      to={link}
      onClick={(e: React.FormEvent<HTMLAnchorElement>) => {
        onLinkClick(e, isBlock)
      }}
    >
      <picture className={styles.defaultImg}>
        <source srcSet={imgDefault} media={`(max-width: ${deviceSizeVar.mobileWidth})`} />
        <img src={imgDefault} alt={altForImage} />
      </picture>
      {!isBlock && (
        <picture className={styles.hoverImg}>
          <source srcSet={imgHover} media={`(max-width: ${deviceSizeVar.mobileWidth})`} />
          <img src={imgHover} alt={altForImage} />
        </picture>
      )}
      <picture className={clsx(styles.lockImg, isBlock && styles.isActive)}>
        <source srcSet={imgLock} media={`(max-width: ${deviceSizeVar.mobileWidth})`} />
        <img src={imgLock} alt={altForImage} />
      </picture>
    </NavLink>
  )
}

export default NavCard
