/* eslint-disable arrow-parens */
import { create } from 'zustand'

interface IActivityPageState {
  curActivityNavCardIndex: number
  setCurNavIndex: (index: number) => void
  setUnFindNavCardIndex: () => void
}

const useStore = create<IActivityPageState>((set) => ({
  curActivityNavCardIndex: 0,
  setCurNavIndex: (index: number) => set({ curActivityNavCardIndex: index }),
  setUnFindNavCardIndex: () => set({ curActivityNavCardIndex: -1 })
}))

export default useStore
