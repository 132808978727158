import React from 'react'
import ActivityDate from 'views/activity240120/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity240120/images/title/t2_pc_01.png'
import ActivityLevelDescription from 'views/activity240120/images/title/t7_pc_01.png'
import ActivityBonus from 'views/activity240120/images/title/t8_pc_01.png'
import ActivityBasicReward from 'views/activity240120/images/title/t9_pc_01.png'
import LuckyStarAvatarFrame from 'images/avatarFrame/avatarFrame_luckyStar.png'
import Coin from 'images/common/coin_gold_01.png'
import Sliver from 'images/common/coin_silver_01.png'
import Gem from 'images/common/gold-yuanbao_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2024/2/5(一) 12:00~2024/2/11(日)23:59</span>
        <br />
        <br />
        <span>彈性延長時間：</span>
        <br />
        <span>2024/2/12(一) 00:00~2024/2/12(一)23:59</span>
        <br />
        <br />
        <span>
          ※ 已開始報名，但於 2/11 23:59 前未累積滿 300
          位的期數，於彈性延長時間內仍可繼續報名並開獎。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間內於老虎機各廳館進行遊戲，若單把倍數達到 50 倍
          (MEGAWIN)，將可依照遊戲廳館報名一個節目座位。
        </span>
        <br />
        <span>
          所有玩家共同報名座位，每期座位僅有 300 個，累積滿位的節目將於數
          分鐘後於「節目現場」頁面進行即時開獎，最高可獲得 100 倍獎勵！
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-5 table-rounded-tl'>老虎機</th>
            <th className='table-title-bg-5'>節目館</th>
            <th className='table-title-bg-5 table-rounded-tr'>獎勵類型</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>尊榮、貴賓、大眾廳</td>
            <td>富貴館</td>
            <td>元寶</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>歡樂廳</td>
            <td>歡樂館</td>
            <td className='table-rounded-br'>銀幣</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>※ 玩家可於同期節目中同時持有多個座位增加中獎率！</span>
        <br />
        <span style={{ color: '#f5e61c' }}>
          ※ 每當一期報名滿額，系統將自動開啟下一期報名，活動期間所有 MEGAWIN 都必定能獲得座位。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityLevelDescription}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityLevelDescription}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        節目開獎一共有 4
        個關卡，每關淘汰指定人數的座位，最後留下的那位玩家就是本期「終極幸運星」！可獲得基底獎勵乘
        100 倍的豪華獎勵！
        被淘汰的玩家，可獲得上個關卡提升的獎勵倍數乘以基底獎勵。只要報名成功，人人有獎！
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>
          ※ 基底獎勵依照「獲得報名資格時那把 MEGAWIN
          的押注額度」進行轉換，對照表請從下方「基底獎勵對照表」查看。
        </span>
        <br />
        <span style={{ color: '#f5e61c' }}>
          ※ 將於該期節目開獎完成後的 10 分鐘內，統一將所有座位的獎勵派送至信箱。
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              第 1 關 雙獅玲瓏轉
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>通關者獎勵</td>
            <td>獎勵倍數升至 2 倍！</td>
          </tr>
          <tr>
            <td>淘汰者獎勵</td>
            <td>待所有遊戲結束後，可獲得１倍獎勵。</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>關卡說明</td>
            <td className='table-rounded-br'>
              「紅獅隊」與「金獅隊」的運氣比拚！轉盤所選隊伍，可全隊通關至下一輪競賽！
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              第 2 關 命運大轉盤
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>通關者獎勵</td>
            <td>獎勵倍數升至 5 倍！</td>
          </tr>
          <tr>
            <td>淘汰者獎勵</td>
            <td>待所有遊戲結束後，可獲得 2 倍獎勵。</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>關卡說明</td>
            <td className='table-rounded-br'>
              第 1~5 組間的命運爭霸！轉盤所選的一個組別，可全組通關至下一輪競賽!
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              第 3 關 蹦蹦生存戰
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>通關者獎勵</td>
            <td>獎勵倍數升至 20 倍！</td>
          </tr>
          <tr>
            <td>淘汰者獎勵</td>
            <td>待所有遊戲結束後，可獲得 5 倍獎勵。</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>關卡說明</td>
            <td className='table-rounded-br'>
              穩住身軀就可以在這場戰爭中生存下去！槌子敲擊玩家所在的架子，掉落的玩家將被淘汰!直到剩餘
              5 位玩家為止!
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              決賽 終極幸運星
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>通關者獎勵</td>
            <td>可獲得 100 倍獎勵！</td>
          </tr>
          <tr>
            <td>淘汰者獎勵</td>
            <td>可獲得 20 倍獎勵。</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>關卡說明</td>
            <td className='table-rounded-br'>
              從 5 位幸運玩家中選出 1 位終極幸運星！
              <br />
              紅色跑燈選中的玩家將被淘汰，最終留下的玩家就是終極幸運星！
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityBonus}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityBonus}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>於富貴館遊玩，並首次進入決賽的玩家皆可獲得幸運星頭像框！</p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={1} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              幸運星頭像框
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='table-rounded-bl table-rounded-br'>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  className='m:hidden'
                  style={{ width: '120px' }}
                  src={LuckyStarAvatarFrame}
                  alt='lucky-avatarFrame'
                />
                <img
                  className='hidden m:block'
                  style={{ width: '30%', maxWidth: '180px' }}
                  src={LuckyStarAvatarFrame}
                  alt='lucky-avatarFrame'
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityBasicReward}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityBasicReward}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              富貴館節目
              <br />
              於大眾、貴賓、尊榮廳押注
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-5'>單把押注額度</th>
            <th className='table-title-bg-5'>基底獎勵</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              49,000 ~ 50,100
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              520 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              39,000 ~ 40,000
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              415 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              30,000 ~ 30,100
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              310 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              24,000 ~ 25,000
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              258 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              19,000 ~ 20,000
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              208 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              15,000 ~ 16,000
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              158 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              11,000 ~ 12,000
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              125 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              10,000 ~ 10,100
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              105 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              7,900 ~ 8,100
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              85 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              6,400 ~ 6,600
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              68 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              5,000 ~ 5,100
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              53 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              3,900 ~ 4,100
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              43 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              3,000 ~ 3,100
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              32 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              2,400 ~ 2,500
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              27 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              1,900 ~ 2,000
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              22 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              1,400 ~ 1,550
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              17 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              1,000 ~ 1,060
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              12 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              700 ~ 750
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              8 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              500 ~ 550
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              6 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              400 ~ 450
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              5 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              280 ~ 360
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              4 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              200 ~ 270
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              3 個
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              150 ~ 190
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              2 個
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Coin}
                alt='coin'
              />
              88 ~ 140
            </td>
            <td className='table-rounded-br'>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Gem}
                alt='coin'
              />
              1 個
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th colSpan={2} className='table-rounded-tl table-title-bg-5 table-rounded-tr'>
              歡樂館節目
              <br />
              於歡樂廳押注
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='table-title-bg-5'>單把押注額度</th>
            <th className='table-title-bg-5'>基底獎勵</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              1,100 ~ 1,080
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              1,300
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              700 ~ 720
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              900
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              500 ~ 540
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              700
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              400 ~ 440
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              600
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              280 ~ 360
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              500
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              200 ~ 270
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              400
            </td>
          </tr>
          <tr>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              150 ~ 180
            </td>
            <td>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              300
            </td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              80 ~ 120
            </td>
            <td className='table-rounded-br'>
              <img
                style={{ width: '25px', margin: '0 5px', verticalAlign: 'sub' }}
                src={Sliver}
                alt='coin'
              />
              200
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>【注意事項】</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          無法獲得座位資格的情境：
          <br />
          (1) 使用道具達到 MEGAWIN 不計算。
          <br />
          (2) 該把 MEGAWIN 遊戲幣入錶底時的時間超出活動截止時間。
        </li>
        <li>
          可獲得的座位數量：
          <br />
          將依照遊戲幣入錶底時的贏分倍數是否達到 50 倍做為門檻，每當遊戲幣入錶底 1 次就可獲得 1
          個座位資格，因此當免費遊戲、吉運祝福、福運祝福、鴻運祝福...等特色達到
          MEGAWIN，將在特色結束後計算為 1 次；招財祝福則每次旋轉都有機會算做 1 個。
        </li>
        <li>
          報名的座位屬於哪一期節目：
          <br />於 MEGAWIN 的贏分入錶底時就已報名成功，後續何時選座位不影響報名的期數。
        </li>
        <li>
          自動選座位功能：
          <br />
          您可於選座位時，點擊「系統自動選」按鈕讓系統快速幫您隨機選擇空位。於節目即將開始前，若尚未選擇座位，為了您的節目參加權益，將自動幫您選擇空位。
        </li>
        <li>
          2024/2/11 23:59後，將不會再開啟新的節目提供報名。如有已開始報名但未累積滿 300
          個座位的節目期數，則該期將成為最後一期，且玩家仍可在彈性延長時限內繼續報名直到該期節目額滿，並正常開獎。如彈性延長時限內沒有集滿座位，則所有報名該期的玩家將統一發送
          1 倍基底獎勵。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
