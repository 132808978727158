import { createBrowserRouter } from 'react-router-dom'
import ErrorPage from 'views/errorPage/ErrorPage.tsx'
import Activity230321 from 'views/activity230321/Activity230321.tsx'
import Activity230611 from 'views/activity230611/Activity230611.tsx'
import Activity230911 from 'views/activity230911/Activity230911.tsx'
import Activity240120 from 'views/activity240120/Activity240120.tsx'
import Activity240903 from 'views/activity240903/Activity240903.tsx'

const routes = createBrowserRouter([
  {
    path: '/',
    element: <ErrorPage />,
    errorElement: <ErrorPage />
  },
  {
    path: '/activity230321/*',
    element: <Activity230321 />,
    errorElement: <ErrorPage />
  },
  {
    path: '/activity230611/*',
    element: <Activity230611 />,
    errorElement: <ErrorPage />
  },
  {
    path: '/activity230911/*',
    element: <Activity230911 />,
    errorElement: <ErrorPage />
  },
  {
    path: '/activity240120/*',
    element: <Activity240120 />,
    errorElement: <ErrorPage />
  },
  {
    path: '/activity240903/*',
    element: <Activity240903 />,
    errorElement: <ErrorPage />
  }
])

export default routes
