import React from 'react'
import BGMobile from 'images/topAwardBG/topAward_m_01.png'
import BGDesktop from 'images/topAwardBG/topAward_pc_01.png'
import styles from './topAwardSingle.module.sass'
import clsx from 'clsx'

interface ITopAwardSingle {
  titleImg: string
  awardImg: string
  content: string
  bannerBackgroundColor: string
  bannerBorderColor: string
  contentBackgroundColor: string
  contentBorderColor: string
}

function TopAwardSingle(props: ITopAwardSingle) {
  const {
    titleImg,
    awardImg,
    content,
    bannerBackgroundColor,
    bannerBorderColor,
    contentBackgroundColor,
    contentBorderColor
  } = props

  return (
    <div className={styles.root}>
      <div
        style={{
          borderColor: bannerBorderColor,
          backgroundColor: bannerBackgroundColor
        }}
        className={clsx(styles.titleContainer)}
      >
        <h4 className={styles.title}>
          <img src={titleImg} alt='title' />
        </h4>
      </div>
      <div
        style={{
          borderColor: contentBorderColor,
          backgroundColor: contentBackgroundColor
        }}
        className={clsx(styles.content, 'relative')}
      >
        <div className={clsx(styles.awardBG, 'm:flex hidden')}>
          <div className={styles.awardM}>
            <img src={awardImg} alt='award' />
          </div>
          <img src={BGMobile} alt='bg-mobile' />
        </div>
        <div className={clsx(styles.awardD)}>
          <img src={awardImg} alt='award' />
        </div>
        <div className={clsx(styles.awardBG, 'block m:hidden')}>
          <img src={BGDesktop} alt='awardBG' />
        </div>
        <div className={styles.contentText} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  )
}

export default TopAwardSingle
