import axios from 'axios'

const $axios = axios.create({
  headers: {
    'True-Client-IP': '127.0.0.1'
  },
  baseURL: '/api'
})

export default $axios
