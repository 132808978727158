/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230321/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230321/images/title/t2_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/3/27(一) 00:00~2023/3/31(五) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>期間內於「限時出任務」活動頁，</span>
        <span style={{ color: '#f5e61c' }}>依照任務條件進行遊玩</span>
        <span>
          ，達成任務領取對應數量的「徽章」。蒐集徽章可於活動頁面中的「徽章商店」兌換各種超稀有道具與好禮喔！
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>● 其它注意事項:</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          活動介面上的三個任務區塊對應任務難度，由上到下為「困難」、「一般」、「簡單」。各廳館各難度的任務皆有每日限制次數。如該廳館難度之任務已達今日可解次數，於玩家點擊領取徽章後，將不派發下個任務給玩家。
        </li>
        <li>每日可解次數於每天凌晨 00:00 重置。</li>
        <li>貴賓廳的任務僅可於貴賓廳的老虎機中進行任務，大眾廳與歡樂廳亦然。</li>
        <li>徽章的累積與兌換獎品不分廳館限制。</li>
        <li>使用道具不計入此活動(包含：押碼、贏分及旋轉次數...等)。</li>
        <li>「旋轉次數」此任務類型，不計算免費遊戲、重轉遊戲、彩金遊戲中贈送的旋轉次數。</li>
        <li>
          「觸發免費遊戲」此任務類型，如玩家觸發福運祝福，其中效果為獲得 1
          次福運免費遊戲，任務觸發次數將計算為「1次」。
        </li>
        <li>
          「觸發免費遊戲」此任務類型，如玩家觸發招財祝福，且於其中幸運觸發免費遊戲 2
          次，任務觸發次數將計算為「2次」(招財祝福贈送的 10 轉非免費遊戲，故只計算其中觸發的 2
          次免費遊戲)。
        </li>
        <li>
          「單把贏分次數」與「單把倍數次數」此兩種任務類型的計算，將依照遊戲幣入錶底時的總金額是否符合任務標準來計次，因此免費遊戲、吉運祝福、福運祝福、鴻運祝福...等特色，將在特色結束後計算為
          1 次；招財祝福則每把都有機會算做 1 次。
        </li>
        <li>活動結束後，活動介面會保留 3 天以便玩家後續兌換獎品，但期間遊玩遊戲無法再獲得徽章。</li>
        <li>活動結束 3 天後，剩餘徽章將全數由系統回收。</li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有最終解釋權及終止，調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
