import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import styles from './topAward.module.sass'
import TopAwardSingle from 'components/topAwardSingle/TopAwardSingle'
import TopAwardDouble from 'components/topAwardDouble/TopAwardDouble'
import { ITopAwardData } from 'declare/index'
import variables from 'theme/common/variables.module.sass'

// Gold
import GoldTitlePC from 'views/activity230611/images/reward/gold_title_pc_01.png'
import GoldTitleM from 'views/activity230611/images/reward/gold_title_m_01.png'
import GoldAwardTitle1 from 'views/activity230611/images/reward/gold_award_1.png'
import GoldAwardTitle2 from 'views/activity230611/images/reward/gold_award_2.png'
import GoldAwardImage1 from 'views/activity230611/images/reward/gold/Gogoro VIVA卡.png'
import GoldAwardImage2 from 'views/activity230611/images/reward/gold/金粽墜飾卡.png'

// Silver
import SilverTitlePC from 'views/activity230611/images/reward/silver_title_pc_01.png'
import SilverTitleM from 'views/activity230611/images/reward/silver_title_m_01.png'
import SilverAwardTitle1 from 'views/activity230611/images/reward/silver_award_1.png'
import SilverAwardTitle2 from 'views/activity230611/images/reward/silver_award_2.png'
import SilverAwardImage1 from 'views/activity230611/images/reward/silver/iPhone14卡.png'
import SilverAwardImage2 from 'views/activity230611/images/reward/silver/Switch卡.png'

// Common
import AwardTitle3 from 'views/activity230611/images/reward/award_3.png'
import AwardImage3 from 'images/award/鴻運卷軸.png'
import AwardTitle4 from 'views/activity230611/images/reward/award_4.png'
import AwardImage4 from 'images/award/威力鑽頭砲.png'
import AwardTitle5 from 'views/activity230611/images/reward/award_5.png'
import AwardImage5 from 'images/award/福運卷軸.png'
import AwardTitle6 from 'views/activity230611/images/reward/award_6.png'
import AwardImage6 from 'images/award/招財樹.png'
import AwardTitle7 from 'views/activity230611/images/reward/award_7.png'
import AwardImage7 from 'images/award/強力鑽頭砲.png'
import AwardTitle8 from 'views/activity230611/images/reward/award_8.png'
import AwardImage8 from 'images/award/元寶(多).png'

const styledTopAwardSingle = {
  contentBackgroundColor: '#0D3824',
  contentBorderColor: '#208847',
  bannerBorderColor: '#39D77D',
  bannerBackgroundColor: '#0E3824'
}

const goldAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle1,
    awardImg: GoldAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">Gogoro VIVA卡</span><br /> 每週2位</p>'
  },
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle2,
    awardImg: GoldAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">金粽墜飾卡</span><br class="m:hidden"/> 每週2位</p>'
  }
]

const goldDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle4,
      awardImg: AwardImage4,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳9星威力鑽頭砲</span><br class="m:block hidden"/> 每週2位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle7,
      awardImg: AwardImage7,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳9星強力鑽頭砲</span><br class="m:block hidden"/> 每週4位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle3,
      awardImg: AwardImage3,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星紫卷</span><br class="m:block hidden"/> 每週2位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle5,
      awardImg: AwardImage5,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星紅卷</span><br class="m:block hidden"/> 每週4位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle6,
      awardImg: AwardImage6,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8星招財樹</span><br class="m:block hidden"/> 每週4位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle8,
      awardImg: AwardImage8,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶3000顆</span><br class="m:block hidden"/> 每週8位</p>'
    }
  ]
]

const silverAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle1,
    awardImg: SilverAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">iPhone14卡</span><br class="m:hidden"/> 每週1位</p>'
  },
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle2,
    awardImg: SilverAwardImage2,
    content:
      '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">Switch卡</span><br class="m:hidden"/> 每週1位</p>'
  }
]

const silverDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle4,
      awardImg: AwardImage4,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳9星威力鑽頭砲</span><br class="m:block hidden"/> 每週3位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle7,
      awardImg: AwardImage7,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳9星強力鑽頭砲</span><br class="m:block hidden"/> 每週6位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle3,
      awardImg: AwardImage3,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星紫卷</span><br class="m:block hidden"/> 每週3位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle5,
      awardImg: AwardImage5,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星紅卷</span><br class="m:block hidden"/> 每週6位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle6,
      awardImg: AwardImage6,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8星招財樹</span><br class="m:block hidden"/> 每週6位</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: AwardTitle8,
      awardImg: AwardImage8,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶300顆</span><br class="m:block hidden"/> 每週50位</p>'
    }
  ]
]

function TopAward() {
  return (
    <section className={styles.root}>
      <h3 className={clsx(styles.title, 'relative')}>
        <picture className='image_obj'>
          <source srcSet={GoldTitleM} media={`(max-width: ${variables.mobileWidth})`} />
          <img src={GoldTitlePC} alt='GoTop' />
        </picture>
      </h3>
      {goldAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      {goldDoubleData.map((el) => (
        <TopAwardDouble key={uuid()} data={el} />
      ))}
      <p>● Gogoro VIVA 卡：價值 6,998,000 金幣。</p>
      <p>● 金粽墜飾卡：價值 1,680,000 金幣。</p>
      <h3 className={clsx(styles.title, 'relative')}>
        <picture className='image_obj'>
          <source srcSet={SilverTitleM} media={`(max-width: ${variables.mobileWidth})`} />
          <img src={SilverTitlePC} alt='GoTop' />
        </picture>
      </h3>
      {silverAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      {silverDoubleData.map((el) => (
        <TopAwardDouble key={uuid()} data={el} />
      ))}
      <p>● iPhone14 卡：價值 2,790,000 金幣。</p>
      <p>● Switch 卡：價值 900,000 金幣。</p>
    </section>
  )
}

export default TopAward
