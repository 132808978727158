/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230611/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230611/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity230611/images/title/t3_pc_01.png'
import ActivityDeadLine from 'views/activity230611/images/title/t5_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/6/19(一) 12:00~2023/6/25(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDeadLine}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDeadLine}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動開始~2023/6/28(三) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>※ 兌換期限過後，如活動材料未使用完畢則將會由系統回收。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動期間內於</span>
        <span style={{ color: '#f5e61c' }}>所有老虎機</span>
        <span>
          遊玩，累積押碼達標可獲得 1
          枚代幣。使用代幣可以遊玩聚寶小瑪莉，並且有機會獲得超稀有道具與好禮！
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>※ 不同的獎勵區間，所需的代幣量也不相同。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          每次遊玩必定可獲得獎勵，其中包含：鴻運卷軸、福運卷軸、吉運卷軸、招財樹、翻倍令牌、元寶、金幣，獲得的道具星等將依據玩家所投入的代幣有所變化。
        </span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-4 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-4 table-rounded-tl'>獎勵廳館星等</th>
            <th className='table-title-bg-4 table-rounded-tr'>所需代幣量</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>貴賓廳 5~8 ★</td>
            <td>1000</td>
          </tr>
          <tr>
            <td>貴賓廳 1~4 ★</td>
            <td>350</td>
          </tr>
          <tr>
            <td>大眾廳 5~8 ★</td>
            <td>100</td>
          </tr>
          <tr>
            <td>大眾廳 1~4 ★</td>
            <td>30</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>歡樂廳 5~8 ★</td>
            <td className='table-rounded-br'>3</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span>● 其它注意事項：</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          活動結束後，活動介面會保留 3 天，在此期間玩家仍可遊玩小瑪莉，但遊玩遊戲已無法再獲得代幣。
        </li>
        <li>活動結束 3 天後，剩餘代幣將全數由系統回收。</li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有最終解釋權及終止，調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
