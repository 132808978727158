import React, { useCallback, useState } from 'react'
import styles from './rate18Alert.module.sass'
import EighteenPlus from 'images/common/rate18+.png'
import r18AlertDialogStore from '../../zustand/r18AlertDialog'
import emitter from 'utils/event'
import eventComment from 'constants/eventCommand'

function Rate18Alert() {
  const [notRemindAgain, setNotRemindAgain] = useState<boolean>(false) // 是否勾選不再顯示
  const { setR18AlertStatus } = r18AlertDialogStore()

  const handleFull18 = useCallback(() => {
    setR18AlertStatus(false)
    if (notRemindAgain) {
      window.document.cookie = 'checkLevel=true'
    }
    emitter.emit(eventComment.CLICK_18_WINDOW_CONFIRM)
  }, [notRemindAgain, setR18AlertStatus])

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.title}>聚寶Online 提醒您</div>
        <div className={styles.content}>
          <div className={styles.eighteenPlus}>
            <img src={EighteenPlus} alt='限制級 18+' />
          </div>
          <div className={styles.text}>
            <p>提醒：本網站服務於遊戲軟體分級為限制級，</p>
            <p>使用本服務前請確認您已滿18歲。</p>
          </div>
          <div className={styles.btn}>
            <a href='https://www.google.com.tw' className={styles.notFullBtn}>
              未滿18歲
            </a>
            <button className={styles.fullBtn} type='button' onClick={handleFull18}>
              已滿18歲
            </button>
          </div>
          <div className={styles.contentBottom} />
        </div>
        <div className={styles.footer}>
          <div className={styles.hint}>
            <input
              id='notRemindAgain'
              type='checkbox'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNotRemindAgain(e.target.checked)
              }}
            />
            <label htmlFor='notRemindAgain'>不再提示</label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rate18Alert
