import React from 'react'
import styles from './topAwardDate.module.sass'
import clsx from 'clsx'
import { ITopAwardDateData } from 'declare'

function TopAwardDate(props: ITopAwardDateData) {
  const {
    sx,
    dateDesktopImg,
    dateMobileImg,
    bannerBackgroundColor,
    bannerBorderColor,
    dateString
  } = props
  return (
    <div className={styles.root} style={{ ...sx }}>
      <div
        style={{
          borderColor: bannerBorderColor,
          backgroundColor: bannerBackgroundColor
        }}
        className={clsx(styles.titleContainer)}
      >
        <h4 className={styles.title}>
          {dateDesktopImg && <img src={dateDesktopImg} className='m:hidden' alt='title' />}
          {dateMobileImg && <img src={dateMobileImg} className='hidden' alt='title' />}
          {dateString && <span className={styles.dateString}>{dateString}</span>}
        </h4>
      </div>
    </div>
  )
}

export default TopAwardDate
