import { Route, Routes } from 'react-router-dom'
import styles from './activity240903.module.sass'
import variable from './styles/variable.module.sass'
import Header from './components/header/Header'
import NavGroup from 'views/activity240903/components/navGroup/NavGroup'
import { INavCard } from 'components/navCard/NavCard'
import DownloadGame from 'components/downloadGame/DownloadGame'
import GoTop from 'views/activity240903/components/goTop/GoTop'
import ActivityContainer from 'components/activity/activityContainer/ActivityContainer'
import { Format } from 'utils/formula'

import activityBtnBT1Default from 'views/activity240903/images/button/navGroup/default/BT_s_01.png'
import activityBtnBT2Default from 'views/activity240903/images/button/navGroup/default/BT_s_02.png'
import activityBtnBT3Default from 'views/activity240903/images/button/navGroup/default/BT_s_03.png'
import activityBtnBT4Default from 'views/activity240903/images/button/navGroup/default/BT_s_04.png'
import activityBtnBT5Default from 'views/activity240903/images/button/navGroup/default/BT_s_05.png'
import activityBtnBT6Default from 'views/activity240903/images/button/navGroup/default/BT_s_06.png'
import activityBtnBT7Default from 'views/activity240903/images/button/navGroup/default/BT_s_07.png'
import activityBtnBT8Default from 'views/activity240903/images/button/navGroup/default/BT_s_08.png'
import activityBtnBT1Hover from 'views/activity240903/images/button/navGroup/hover/BT_s_01.png'
import activityBtnBT2Hover from 'views/activity240903/images/button/navGroup/hover/BT_s_02.png'
import activityBtnBT3Hover from 'views/activity240903/images/button/navGroup/hover/BT_s_03.png'
import activityBtnBT4Hover from 'views/activity240903/images/button/navGroup/hover/BT_s_04.png'
import activityBtnBT5Hover from 'views/activity240903/images/button/navGroup/hover/BT_s_05.png'
import activityBtnBT6Hover from 'views/activity240903/images/button/navGroup/hover/BT_s_06.png'
import activityBtnBT7Hover from 'views/activity240903/images/button/navGroup/hover/BT_s_07.png'
import activityBtnBT8Hover from 'views/activity240903/images/button/navGroup/hover/BT_s_08.png'
import activityBtnBT1Lock from 'views/activity240903/images/button/navGroup/lock/BT_s_01.png'
import activityBtnBT2Lock from 'views/activity240903/images/button/navGroup/lock/BT_s_02.png'
import activityBtnBT3Lock from 'views/activity240903/images/button/navGroup/lock/BT_s_03.png'
import activityBtnBT4Lock from 'views/activity240903/images/button/navGroup/lock/BT_s_04.png'
import activityBtnBT5Lock from 'views/activity240903/images/button/navGroup/lock/BT_s_05.png'
import activityBtnBT6Lock from 'views/activity240903/images/button/navGroup/lock/BT_s_06.png'
import activityBtnBT7Lock from 'views/activity240903/images/button/navGroup/lock/BT_s_07.png'
import activityBtnBT8Lock from 'views/activity240903/images/button/navGroup/lock/BT_s_08.png'
import activityContentTitle01 from 'views/activity240903/images/pageHeader/BT_W_01.png'
import activityContentTitle02 from 'views/activity240903/images/pageHeader/BT_W_02.png'
import activityContentTitle03 from 'views/activity240903/images/pageHeader/BT_W_03.png'
import activityContentTitle04 from 'views/activity240903/images/pageHeader/BT_W_04.png'
import activityContentTitle05 from 'views/activity240903/images/pageHeader/BT_W_05.png'
import activityContentTitle06 from 'views/activity240903/images/pageHeader/BT_W_06.png'
import activityContentTitle07 from 'views/activity240903/images/pageHeader/BT_W_07.png'
import activityContentTitle08 from 'views/activity240903/images/pageHeader/BT_W_08.png'
import Activity1 from 'views/activity240903/components/activityContentData/1/Activity'
import Activity2 from 'views/activity240903/components/activityContentData/2/Activity'
import Activity3 from 'views/activity240903/components/activityContentData/3/Activity'
import Activity4 from 'views/activity240903/components/activityContentData/4/Activity'
import Activity5 from 'views/activity240903/components/activityContentData/5/Activity'
import Activity6 from 'views/activity240903/components/activityContentData/6/Activity'
import Activity7 from 'views/activity240903/components/activityContentData/7/Activity'
import Activity8 from 'views/activity240903/components/activityContentData/8/Activity'

const TITLE = '聚寶ONLINE-BMW伴君行江湖'

const navGroupData: Array<INavCard> = [
  {
    buttonStatus: {
      imgDefault: activityBtnBT1Default,
      imgHover: activityBtnBT1Hover,
      imgLock: activityBtnBT1Lock
    },
    altForImage: '名車金駕讚',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 9, 3, 12, 0, 0),
    link: '/activity240903/1#target',
    imgTitle: activityContentTitle01,
    component: <Activity1 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT2Default,
      imgHover: activityBtnBT2Hover,
      imgLock: activityBtnBT2Lock
    },
    altForImage: '霹靂集英傳',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 9, 3, 12, 0, 0),
    link: '/activity240903/2#target',
    imgTitle: activityContentTitle02,
    component: <Activity2 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT3Default,
      imgHover: activityBtnBT3Hover,
      imgLock: activityBtnBT3Lock
    },
    altForImage: '刮卡贏天下',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 9, 3, 12, 0, 0),
    link: '/activity240903/3#target',
    imgTitle: activityContentTitle03,
    component: <Activity3 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT4Default,
      imgHover: activityBtnBT4Hover,
      imgLock: activityBtnBT4Lock
    },
    altForImage: '霹靂簽豪禮',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 9, 4, 12, 0, 0),
    link: '/activity240903/4#target',
    imgTitle: activityContentTitle04,
    component: <Activity4 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT5Default,
      imgHover: activityBtnBT5Hover,
      imgLock: activityBtnBT5Lock
    },
    altForImage: '金幣免費送',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 9, 4, 12, 0, 0),
    link: '/activity240903/5#target',
    imgTitle: activityContentTitle05,
    component: <Activity5 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT6Default,
      imgHover: activityBtnBT6Hover,
      imgLock: activityBtnBT6Lock
    },
    altForImage: '武林至尊榜',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 9, 4, 12, 0, 0),
    link: '/activity240903/6#target',
    imgTitle: activityContentTitle06,
    component: <Activity6 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT7Default,
      imgHover: activityBtnBT7Hover,
      imgLock: activityBtnBT7Lock
    },
    altForImage: '紅包慶月圓',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 9, 6, 12, 0, 0),
    link: '/activity240903/7#target',
    imgTitle: activityContentTitle07,
    component: <Activity7 />
  },
  {
    buttonStatus: {
      imgDefault: activityBtnBT8Default,
      imgHover: activityBtnBT8Hover,
      imgLock: activityBtnBT8Lock
    },
    altForImage: '埃及征途榜',
    isBlock: new Date(Date.now()) < Format.getOpenDate(2024, 9, 22, 12, 0, 0),
    link: '/activity240903/8#target',
    imgTitle: activityContentTitle08,
    component: <Activity8 />
  }
]

function Activity() {
  document.title = TITLE

  return (
    <div className={styles.root}>
      <div className={styles.background}></div>
      <DownloadGame zIndex={variable.zDownloadGame} colorTheme='newYearPrimary' />
      <Header />
      <GoTop zIndex={variable.zGoTop} colorFilter='hue-rotate(15deg)' />
      <section className={styles.navGroup}>
        <NavGroup datas={navGroupData} />
      </section>
      <div id='target'>
        <Routes>
          <Route
            path='/:id'
            element={<ActivityContainer activityData={navGroupData} colorTheme='moon2024Primary' />}
          />
          <Route
            index
            element={<ActivityContainer activityData={navGroupData} colorTheme='moon2024Primary' />}
          />
        </Routes>
      </div>
    </div>
  )
}

export default Activity
