import { useEffect, useState } from 'react'
import emitter from 'utils/event'
import eventComment from 'constants/eventCommand'
import { Get } from 'utils/commonFunction'

function useHeaderAnimationToggle() {
  const [headerAnimationIsActive, setHeaderAnimationIsActive] = useState(
    Get.cookie('checkLevel') ? true : false
  )

  const Window18OnConfirm = () => {
    setHeaderAnimationIsActive(true)
  }

  useEffect(() => {
    emitter.on(eventComment.CLICK_18_WINDOW_CONFIRM, Window18OnConfirm)

    return () => {
      emitter.off(eventComment.CLICK_18_WINDOW_CONFIRM, Window18OnConfirm)
    }
  }, [])

  return [headerAnimationIsActive]
}

export default useHeaderAnimationToggle
