/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230611/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230611/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity230611/images/title/t3_pc_01.png'
import ActivityExchange from 'views/activity230611/images/title/t5_pc_01.png'
import ActivityBonus from 'views/activity230611/images/title/t6_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/6/26(一) 00:00~2023/7/2(日)23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityBonus}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityBonus}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動期間每天三時段：</span>
        <span style={{ color: '#f5e61c' }}>
          凌晨 2:00~4:00、中午 12:00~14:00、晚上 20:00~22:00點，成長值加碼 +30%！
        </span>
        <br />
        <span>把握該時段進行澆水，可以大幅提高福樹升級效率！</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityExchange}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityExchange}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動開始~2023/7/5(三) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間內於所有老虎機遊玩，累積押碼達標可獲得 1
          個水滴。使用水滴兌換澆水器後，幫福樹澆水累積成長值進行升級。福樹等級越高，採收時可獲得越稀有的道具喔！
        </span>
      </p>
      <p>&nbsp;</p>
      <p style={{ margin: `0.75rem 0` }}>
        <span style={{ color: '#f5e61c' }}>
          ※ 使用越大的澆水器澆水，有機會消耗越少水滴，產生更高的成長值！
        </span>
      </p>
      <p style={{ margin: `0.75rem 0` }}>
        <span style={{ color: '#f5e61c' }}>※ 需將福樹升級至 2 等以上才可進行採收。</span>
      </p>
      <p style={{ margin: `0.75rem 0` }}>
        <span style={{ color: '#f5e61c' }}>※ 福樹等級將於每次採收獎勵後重置為等級 1。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          範例：小寶於福樹等級 24 時，採收獎勵並獲得貴賓廳 7 星道具後，福樹等級會重置為等級 1。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>每次採收可獲得獎勵必定為道具，包含：</span>
        <span style={{ color: '#f5e61c' }}>鴻運卷軸、福運卷軸、吉運卷軸、招財樹</span>
        <span>，可依據福樹等級獲得指定星級的稀有道具一個。</span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-4 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-4 table-rounded-tl'>福樹等級</th>
            <th className='table-title-bg-4 table-rounded-tr'>獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>等級 25</td>
            <td>貴賓廳 8★道具</td>
          </tr>
          <tr>
            <td>等級 24</td>
            <td>貴賓廳 7★道具</td>
          </tr>
          <tr>
            <td>等級 23</td>
            <td>貴賓廳 6★道具</td>
          </tr>
          <tr>
            <td>等級 22</td>
            <td>貴賓廳 5★道具</td>
          </tr>
          <tr>
            <td>等級 21</td>
            <td>貴賓廳 4★道具</td>
          </tr>
          <tr>
            <td>等級 20</td>
            <td>貴賓廳 3★道具</td>
          </tr>
          <tr>
            <td>等級 19</td>
            <td>貴賓廳 2★道具</td>
          </tr>
          <tr>
            <td>等級 18</td>
            <td>貴賓廳 1★道具</td>
          </tr>
          <tr>
            <td>等級 17</td>
            <td>大眾廳 8★道具</td>
          </tr>
          <tr>
            <td>等級 16</td>
            <td>大眾廳 7★道具</td>
          </tr>
          <tr>
            <td>等級 15</td>
            <td>大眾廳 6★道具</td>
          </tr>
          <tr>
            <td>等級 14</td>
            <td>大眾廳 5★道具</td>
          </tr>
          <tr>
            <td>等級 13</td>
            <td>大眾廳 4★道具</td>
          </tr>
          <tr>
            <td>等級 12</td>
            <td>大眾廳 3★道具</td>
          </tr>
          <tr>
            <td>等級 11</td>
            <td>大眾廳 2★道具</td>
          </tr>
          <tr>
            <td>等級 10</td>
            <td>大眾廳 1★道具</td>
          </tr>
          <tr>
            <td>等級 9</td>
            <td>歡樂廳 8★道具</td>
          </tr>
          <tr>
            <td>等級 8</td>
            <td>歡樂廳 7★道具</td>
          </tr>
          <tr>
            <td>等級 7</td>
            <td>歡樂廳 6★道具</td>
          </tr>
          <tr>
            <td>等級 6</td>
            <td>歡樂廳 5★道具</td>
          </tr>
          <tr>
            <td>等級 5</td>
            <td>歡樂廳 4★道具</td>
          </tr>
          <tr>
            <td>等級 4</td>
            <td>歡樂廳 3★道具</td>
          </tr>
          <tr>
            <td>等級 3</td>
            <td>歡樂廳 2★道具</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>等級 2</td>
            <td className='table-rounded-br'>歡樂廳 1★道具</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span>● 其它注意事項：</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          當使用複數個澆水器澆水，會於福樹達到等級 25
          滿等時停止澆水，且將未使用的澆水器換算為等值水滴進行退還。
          <br />
          例：使用一次澆水器 A 需消耗 100 水滴。小寶花費 1,000 水滴欲使用澆水器 A 澆水 10
          次，於澆到第 5 次時福樹達到滿等。則系統將停止澆水，且退還 500 水滴。
        </li>
        <li>
          活動結束後，活動介面會保留 3
          天，在此期間玩家仍可使用水滴進行澆水與採收獎勵，但遊玩遊戲已無法再獲得水滴。
        </li>
        <li>活動結束 3 天後，剩餘水滴將全數由系統回收。</li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有最終解釋權及終止，調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
