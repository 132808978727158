import React from 'react'
import styles from './errorPage.module.sass'

function ErrorPage() {
  return (
    <div className={styles.root}>
      <div className={styles.errorImg} />
    </div>
  )
}

export default ErrorPage
