/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity240120/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity240120/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity240120/images/title/t3_pc_01.png'
import ActivityDeadline from 'views/activity240120/images/title/t5_pc_01.png'
import ActivityBonus from 'views/activity240120/images/title/t6_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2024/2/12(一) 10:00~2024/2/18(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityBonus}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityBonus}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動期間每天三時段：</span>
        <span style={{ color: '#f5e61c' }}>
          凌晨2:00~4:00、中午12:00~14:00、晚上20:00~22:00點，成長值加碼+30%！
        </span>
        <br />
        <span>把握該時段進行澆水，可以大幅提高福樹升級效率！</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDeadline}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDeadline}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動開始~2024/2/21(三) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間內於所有老虎機遊玩，累積押碼達標可獲得 1
          個水滴。使用水滴兌換澆水器後，幫福樹澆水累積成長值進行升級。福樹等級越高，採收時可獲得越稀有的道具喔！
          若想蒐集更多水滴，也可前往活動頁面購買【開運水滴賀禮】，內含額外加贈的水滴！
        </span>
        <br />
        <br />
        <span style={{ color: '#f5e61c' }}>
          ※ 使用越大的澆水器澆水，有機會消耗越少水滴，產生更高的成長值！
        </span>
        <br />
        <span style={{ color: '#f5e61c' }}>※ 需將福樹升級至 2 等以上才可進行採收。</span>
        <br />
        <span style={{ color: '#f5e61c' }}>※ 福樹等級將於每次採收獎勵後重置為等級 1。</span>
        <br />
        <br />
        <span>
          範例：小寶於福樹等級 24 時，採收獎勵並獲得貴賓廳 7 星道具後，福樹等級會重置為等級 1。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>每次採收可獲得獎勵必定為道具，包含：</span>
        <span style={{ color: '#f5e61c' }}>鴻運卷軸、福運卷軸、吉運卷軸、招財樹</span>
        <span>，可依據福樹等級獲得指定星級的稀有道具一個。</span>
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-5 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-5 table-rounded-tl'>福樹等級</th>
            <th className='table-title-bg-5 table-rounded-tr'>獎勵內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>等級 33</td>
            <td>尊榮廳 8★ 道具</td>
          </tr>
          <tr>
            <td>等級 32</td>
            <td>尊榮廳 7★ 道具</td>
          </tr>
          <tr>
            <td>等級 31</td>
            <td>尊榮廳 6★ 道具</td>
          </tr>
          <tr>
            <td>等級 30</td>
            <td>尊榮廳 5★ 道具</td>
          </tr>
          <tr>
            <td>等級 29</td>
            <td>尊榮廳 4★ 道具</td>
          </tr>
          <tr>
            <td>等級 28</td>
            <td>尊榮廳 3★ 道具</td>
          </tr>
          <tr>
            <td>等級 27</td>
            <td>尊榮廳 2★ 道具</td>
          </tr>
          <tr>
            <td>等級 26</td>
            <td>尊榮廳 1★ 道具</td>
          </tr>
          <tr>
            <td>等級 25</td>
            <td>貴賓廳 8★ 道具</td>
          </tr>
          <tr>
            <td>等級 24</td>
            <td>貴賓廳 7★ 道具</td>
          </tr>
          <tr>
            <td>等級 23</td>
            <td>貴賓廳 6★ 道具</td>
          </tr>
          <tr>
            <td>等級 22</td>
            <td>貴賓廳 5★ 道具</td>
          </tr>
          <tr>
            <td>等級 21</td>
            <td>貴賓廳 4★ 道具</td>
          </tr>
          <tr>
            <td>等級 20</td>
            <td>貴賓廳 3★ 道具</td>
          </tr>
          <tr>
            <td>等級 19</td>
            <td>貴賓廳 2★ 道具</td>
          </tr>
          <tr>
            <td>等級 18</td>
            <td>貴賓廳 1★ 道具</td>
          </tr>
          <tr>
            <td>等級 17</td>
            <td>大眾廳 8★ 道具</td>
          </tr>
          <tr>
            <td>等級 16</td>
            <td>大眾廳 7★ 道具</td>
          </tr>
          <tr>
            <td>等級 15</td>
            <td>大眾廳 6★ 道具</td>
          </tr>
          <tr>
            <td>等級 14</td>
            <td>大眾廳 5★ 道具</td>
          </tr>
          <tr>
            <td>等級 13</td>
            <td>大眾廳 4★ 道具</td>
          </tr>
          <tr>
            <td>等級 12</td>
            <td>大眾廳 3★ 道具</td>
          </tr>
          <tr>
            <td>等級 11</td>
            <td>大眾廳 2★ 道具</td>
          </tr>
          <tr>
            <td>等級 10</td>
            <td>大眾廳 1★ 道具</td>
          </tr>
          <tr>
            <td>等級 9</td>
            <td>歡樂廳 8★ 道具</td>
          </tr>
          <tr>
            <td>等級 8</td>
            <td>歡樂廳 7★ 道具</td>
          </tr>
          <tr>
            <td>等級 7</td>
            <td>歡樂廳 6★ 道具</td>
          </tr>
          <tr>
            <td>等級 6</td>
            <td>歡樂廳 5★ 道具</td>
          </tr>
          <tr>
            <td>等級 5</td>
            <td>歡樂廳 4★ 道具</td>
          </tr>
          <tr>
            <td>等級 4</td>
            <td>歡樂廳 3★ 道具</td>
          </tr>
          <tr>
            <td>等級 3</td>
            <td>歡樂廳 2★ 道具</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>等級 2</td>
            <td className='table-rounded-br'>歡樂廳 1★ 道具</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>【注意事項】</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          當使用複數個澆水器澆水，會於福樹達到等級 33
          滿等時停止澆水，且將未使用的澆水器換算為等值水滴進行退還。例：使用一次澆水器A需消耗 100
          水滴。小寶花費 1,000 水滴欲使用澆水器A澆水 10 次，於澆到第 5
          次時福樹達到滿等。則系統將停止澆水，且退還 500 水滴。
        </li>
        <li>
          活動結束後，活動介面會保留 3
          天，在此期間玩家仍可使用水滴進行澆水與採收獎勵，但遊玩遊戲已無法再獲得水滴。
        </li>
        <li>活動結束 3 天後，剩餘水滴將全數由系統回收。</li>
        <li>
          部分優惠商品每個遊戲帳號有限制購買次數，成功購買後將無法更換。
          <br />
          ※特別提醒您，官網公告之首儲優惠活動每個遊戲帳號僅限購一次，購買其他任意商品(不論平台方)都將永久失去超值首儲方案的購買資格。
        </li>
        <li>部分優惠商品為限時販售，活動時間判定以 GMT+8 為主，成功購買後將無法更換。</li>
        <li>部分優惠商品為機會中獎商品，消費者購買或參與活動不代表即可 獲得特定商品。</li>
        <li>
          限時限次活動商品以「收款平台方收據上的購買時間」判斷是否成功以優惠內容購買(舉例：安卓、iOS
          提供收據上的付款時間)，因此若發生付款失敗、交易取消、延遲到帳等收款平台方之狀況，該筆交易將無法獲得活動商品優惠內容，系統將自動以付款金額
          1:1 比值給予對應點數發送。
        </li>
        <li>
          由於依照平台方提供收據上的紀錄時間作為判斷依據來扣除限購次數，故當您點選購買後，如平台方發生結帳失敗、延遲發送收據或其他緣由造成未完成結帳，品項不分儲值管道均維持顯示限購優惠相關資訊(包含首儲亦適用此情形)。
        </li>
        <li>
          當購買複數個顯示限制購買次數之商品時(含首儲)，於結帳後將依照各項儲值管道處理之流程及速度，由系統自動判定，僅有符合限購次數之商品可獲得優惠，其他品項將依照付款金額
          1:1 比值給予對應點數。
        </li>
        <li>
          若玩家同時於不同管道儲值繳費，有小機率發生因系統延遲，而導致最後獲得優惠商品非第一筆交易之商品。
        </li>
        <li>
          建議玩家進行儲值時，先購買單一品項並結帳，於確認收到購買商品後再進行第二筆儲值或是切換帳號行為，以確保最終獲得商品與您認知一致。
        </li>
        <li>
          儲值優惠內容以聚寶 Online
          官方網站公告為主。如因故導致無法執行時，本公司有權修改、暫停或停止儲值優惠之所有相關內容。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
