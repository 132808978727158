/* eslint-disable max-len */
import React from 'react'
import ActivityDate from 'views/activity230321/images/title/t1_pc_01.png'
import ActivityMethod from 'views/activity230321/images/title/t2_pc_01.png'
import ActivityAward from 'views/activity230321/images/title/t3_pc_01.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2023/4/8(六) 00:00~2023/4/11(二) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>期間內每天提升等級達「衝等大挑戰」活動介面門檻，將可獲得福運卷軸！並依照當日</span>
        <span style={{ color: '#f5e61c' }}>提升的總等級量，擇優發送道具獎勵一個！</span>
        <span>獎勵將於</span>
        <span style={{ color: '#f5e61c' }}> 4/12 晚上 18 點</span>
        <span>前，陸續發送至玩家信箱。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <table className='table-bg-3 table-radius'>
        <thead>
          <tr>
            <th className='table-title-bg-3 table-rounded-tl'>每日累積升級門檻</th>
            <th className='table-title-bg-3 table-rounded-tr'>獎項內容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1000 等</td>
            <td>貴賓廳 8 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>800 等</td>
            <td>貴賓廳 7 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>600 等</td>
            <td>貴賓廳 6 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>400 等</td>
            <td>貴賓廳 4 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>300 等</td>
            <td>貴賓廳 3 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>200 等</td>
            <td>貴賓廳 2 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>150 等</td>
            <td>貴賓廳 1 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>100 等</td>
            <td>大眾廳 8 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>75 等</td>
            <td>大眾廳 7 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>50 等</td>
            <td>大眾廳 6 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>30 等</td>
            <td>大眾廳 4 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>20 等</td>
            <td>大眾廳 3 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td>10 等</td>
            <td>大眾廳 1 星福運卷軸 1 個</td>
          </tr>
          <tr>
            <td className='table-rounded-bl'>1 等</td>
            <td className='table-rounded-br'>歡樂廳 8 星福運卷軸 1 個</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span>範例：</span>
        <br />
        <span>
          玩家 A 於 4/8 等級從 8 級升到 308 級，累積升級 300 等，4/9 等級從 308 級升到 408
          級，累積升級 100 等，4/10~4/11 玩家未升級；則 4/8 獎勵為貴賓廳 3 星福運卷軸 1 個、4/9
          獎勵為大眾廳 8 星福運卷軸 1 個、4/10~4/11 未獲得獎勵，所有獲得獎勵將於 4/12 晚上 18
          點前，寄至玩家信箱。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>官方保有最終解釋權及終止，調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶 Online 祝福您中大獎</p>
    </>
  )
}

export default Activity
