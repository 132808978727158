import BaseLayout from 'layout/baseLayout/BaseLayout'
import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import router from 'router/index'
import styles from './app.module.sass'
import 'theme/common/theme.module.sass'
import 'theme/common/zIndex.module.sass'
import { Get } from 'utils/commonFunction'
import Rate18Alert from 'components/rate18Alert/Rate18Alert'
import clsx from 'clsx'
import r18AlertDialogStore from './zustand/r18AlertDialog'

function App() {
  const { setR18AlertStatus, open } = r18AlertDialogStore()

  useEffect(() => {
    const checkLevelCookie: string = Get.cookie('checkLevel')
    if (!checkLevelCookie) {
      setR18AlertStatus(true)
    }
  }, [setR18AlertStatus])

  useEffect(() => {
    if (open) {
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('html').style.overflow = 'overlay'
    }
  }, [open])

  return (
    <div className={styles.root}>
      <div className={clsx(styles.rate18Alert, 'rate18AlertIndex', open ? `${styles.show}` : '')}>
        <div className={styles.rate18AlertBG} />
        <Rate18Alert />
      </div>
      <BaseLayout>
        <RouterProvider router={router} />
      </BaseLayout>
    </div>
  )
}

export default App
