const Format = {
  getOpenDate: (
    year: number,
    month: number,
    date: number,
    hour: number,
    minutes: number,
    second: number
  ): Date => new Date(year, month - 1, date, hour, minutes, second)
}

export { Format }
