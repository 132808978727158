import React from 'react'
import clsx from 'clsx'
import styles from './topAwardTriple.module.sass'
import { ITopAwardData } from 'declare/index'

interface ITopAwardTriple {
  data: Array<ITopAwardData>
}

function TopAwardTriple(props: ITopAwardTriple) {
  const { data } = props
  return (
    <div className={styles.root}>
      {data.map((el: ITopAwardData) => (
        <div key={el.content} className={styles.block}>
          <div
            style={{
              borderColor: el.bannerBorderColor,
              backgroundColor: el.bannerBackgroundColor
            }}
            className={clsx(styles.titleContainer)}
          >
            <h4 className={styles.title}>
              <img src={el.titleImg} alt='title' />
            </h4>
          </div>
          <div
            style={{
              borderColor: el.contentBorderColor,
              backgroundColor: el.contentBackgroundColor
            }}
            className={clsx(styles.content, 'relative')}
          >
            <div className={styles.award}>
              <img src={el.awardImg} alt='award' />
            </div>
            <div className={styles.contentText} dangerouslySetInnerHTML={{ __html: el.content }} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default TopAwardTriple
