import React from 'react'
import styles from './redEnvelopeAward.module.sass'

interface AwardInfo {
  hallName: string
  imageUrl: string
  productName: string
  productCount: string
  otherAward: string
}

interface ITopAwardSingle {
  data?: AwardInfo[]
}

function CommonAward(props: ITopAwardSingle) {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {props.data.map((item: AwardInfo) => (
          <div key={item.hallName} className={styles.frame}>
            <h3 className={styles.title}>{item.hallName}</h3>
            <p className={styles.otherAwardText}>{item.otherAward}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CommonAward
