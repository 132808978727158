import React from 'react'
import ActivityDate from 'views/activity240903/images/title/t1.png'
import ActivityMethod from 'views/activity240903/images/title/t2.png'
import ActivityDesignated from 'views/activity240903/images/title/t4.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2024/9/23(一) 12:00~2024/9/29(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>【埃及秘寶】</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>活動期間遊玩指定遊戲，將於</span>
        <span style={{ color: '#f5e61c' }}>每週日</span>
        <span>24:00後，依照「積分榜-龍榜」指定廳館</span>
        <span style={{ color: '#f5e61c' }}>累積贏分排名</span>
        <span>
          結算，於指定名次內贈送百萬獎金！若有同分的狀況，則以時間先後順序排名，獎勵將於結算後5分鐘內發送至信箱。
        </span>
      </p>
      <p>&nbsp;</p>
      <table
        className='table-bg-6 table-radius'
        style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              colSpan={3}
              className='table-rounded-tl table-title-bg-6 table-rounded-tr'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              積分龍榜獎勵
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              廳館
            </th>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              名次
            </th>
            <th
              className='table-title-bg-6'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px', color: '#ffde9e' }}
            >
              獎勵
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              尊榮 貴賓廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>3,500,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>1,500,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>500,000 金幣</td>
          </tr>

          <tr>
            <td rowSpan={5} style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              大眾廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>200,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>100,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>50,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 4 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>30,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 5 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>20,000 金幣</td>
          </tr>
          <tr>
            <td
              rowSpan={4}
              className='table-rounded-bl'
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              歡樂廳
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>5,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>3,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>1,500 金幣</td>
          </tr>
          <tr>
            <td
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
              className='table-rounded-br'
            >
              第 4~10 名
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>1,000 金幣</td>
          </tr>
        </tbody>
      </table>

      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
