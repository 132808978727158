import React from 'react'

import FullImageM from 'views/activity230321/images/banner/bg_m_01.png'
import FullImageTopD from 'views/activity230321/images/banner/bg_top_pc_01.png'
import FullImageBottomD from 'views/activity230321/images/banner/bg_bottom_pc_01.png'
import CarD from 'views/activity230321/images/banner/car_pc_01.png'
import StarD from 'views/activity230321/images/banner/star_pc_01.png'
import clsx from 'clsx'
import styles from './header.module.sass'
import useHeaderAnimationToggle from 'hook/useHeaderAnimationToggle'

function Header() {
  const [headerAnimationIsActive] = useHeaderAnimationToggle()

  return (
    <header className={styles.root}>
      <div className={styles.fullImageMobile}>
        <div className='image_obj'>
          <img src={FullImageM} alt='full-img-m' />
        </div>
      </div>
      <div className={styles.fullImageDesktop}>
        <div className={styles.top}>
          <div className='image_obj'>
            <img src={FullImageTopD} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.carContainer}>
          <div className={clsx(styles.carAnim, headerAnimationIsActive && styles.isActive)}>
            <div className={styles.car}>
              <div className='image_obj'>
                <img src={CarD} alt='car-m' />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={clsx(styles.star, styles.star1)}>
            <div className='image_obj'>
              <img src={StarD} alt='star-img-d' />
            </div>
          </div>
          <div className={clsx(styles.star, styles.star2)}>
            <div className='image_obj'>
              <img src={StarD} alt='star-img-d' />
            </div>
          </div>
          <div className={clsx(styles.star, styles.star3)}>
            <div className='image_obj'>
              <img src={StarD} alt='star-img-d' />
            </div>
          </div>
          <div className='image_obj'>
            <img src={FullImageBottomD} alt='full-img-bottom-m' />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
