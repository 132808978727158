const link = {
  OFFICIAL_WEBSITE: 'https://gbaoonline.com/',
  LINE: 'https://page.line.me/106muoyp?openQrModal=true',
  FACEBOOK: 'https://www.facebook.com/gbaoonline',
  DOWNLOAD_GAME_ANDROID: 'https://play.google.com/store/apps/details?id=com.jixi.android.gbao888',
  DOWNLOAD_GAME_APPLE: 'https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
}

export const { OFFICIAL_WEBSITE, LINE, FACEBOOK, DOWNLOAD_GAME_ANDROID, DOWNLOAD_GAME_APPLE } = link

export default link
