import React from 'react'
import ActivityDate from 'views/activity240903/images/title/t1.png'
import ActivityMethod from 'views/activity240903/images/title/t2.png'
import ActivityReward from 'views/activity240903/images/title/t3.png'
import ActivityDesignated from 'views/activity240903/images/title/t4.png'
import AwardImagePurple from 'images/award/鴻運卷軸.png'
import AwardImageBullion from 'images/award/禮金卡_一萬金塊_.png'
import AwardImageIphone16 from 'images/award/禮金卡_iphone16.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>第一波：2024/9/7(六) 22:00~23:59</span>
        <br />
        <span>第二波：2024/9/17(二) 22:00~23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>所有「獨家老虎機」。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間內於<span style={{ color: '#f5e61c' }}>指定遊戲大眾廳</span>
          以上廳館進行遊戲，單局贏分達各廳設定的門檻即可選擇1個紅包。{' '}
        </span>
        <br />
        <br />
        <span>
          每時段有機會開出「iPhone16禮金卡」（價值3,000,000金幣）、「金塊禮金卡」（價值1,000,000金幣）及超稀有8星鴻運卷軸！紅包限時限量提供，敬請把握機會，錯過可惜！{' '}
        </span>
        <p>&nbsp;</p>
        <span>※ 獎勵將以信件形式，在10分鐘內寄送至信箱。</span>
      </p>
      <p>&nbsp;</p>
      <p style={{ color: '#ffde9e' }}>各廳館單把贏分門檻：</p>
      <p>&nbsp;</p>
      <table
        className='table-bg-6 table-radius'
        style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              className=' table-title-bg-6 '
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              廳館
            </th>
            <th
              className=' table-title-bg-6 '
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              單把贏分門檻
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>尊榮廳</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>金幣2,500,000 </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>貴賓廳</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>金幣400,000</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>大眾廳</td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>金幣50,000</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityReward}
          alt='activity-reward'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityReward}
          alt='activity-reward'
        />
      </p>
      <p>&nbsp;</p>
      <table
        className='table-bg-6 table-radius'
        style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              className=' table-title-bg-6 '
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              尊榮廳
            </th>
            <th
              className=' table-title-bg-6 '
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              貴賓廳
            </th>
            <th
              className=' table-title-bg-6 '
              style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}
            >
              大眾廳
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div>
                <img src={AwardImageIphone16} alt='activity-award-iphone16' width={'85%'} />
                <p>iPhone16禮金卡</p>
                <p>每時段1位</p>
              </div>
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div>
                <img src={AwardImageBullion} alt='activity-award-bullion' width={'85%'} />
                <p>金塊禮金卡</p>
                <p>每時段1位</p>
              </div>
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <div>
                <img src={AwardImagePurple} alt='activity-award-purple' />
                <p>大眾廳8★鴻運卷軸</p>
                <p>每時段2位</p>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <p>尊榮廳3★鴻運卷軸、尊榮廳1★福運卷軸、尊榮廳1★招財樹、元寶1,888個。</p>
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <p>貴賓廳5★鴻運卷軸、貴賓廳3★福運卷軸、貴賓廳3★招財樹、元寶288個。</p>
            </td>
            <td style={{ border: '1px solid #00B6DE', borderRadius: '2px' }}>
              <p>大眾廳5★鴻運卷軸、大眾廳3★福運卷軸、大眾廳3★招財樹、元寶28個。</p>
            </td>
          </tr>
        </tbody>
      </table>

      <ul>
        <p>
          <li>iPhone16禮金卡：價值3,000,000金幣。</li>
        </p>
        <p>
          <li>金塊禮金卡：價值1,000,000金幣。</li>
        </p>
      </ul>
      <p>
        <span style={{ color: '#ffde9e' }}>【注意事項】</span>
      </p>
      <ol
        style={{
          textAlign: 'justify'
        }}
      >
        <li>
          「單把贏分」之計算，將依照遊戲幣入錶底的時機算做1把，因此免費遊戲、
          吉運祝福、福運祝福、鴻運祝福...等特色，將在特色結束後計算為1把贏分；招財樹與翻倍令牌則每次消耗特色剩餘轉數時算做1把贏分。
        </li>
        <li>使用道具獲得的贏分不計入本次活動，即使達到贏分門檻也無法獲得選紅包機會。</li>
        <li>
          紅包限時限量發完為止。如特色結算時，可搶紅包時段已過或是數量已發送完畢，則無法獲得紅包。舉例：如於活動時間結束前，玩家使用的招財樹尚未將第10把旋轉完成並結算，則就算其中含有一把贏分符合抽紅包門檻，也無法獲得抽紅包資格。{' '}
        </li>
        <li>每個時段的紅包獲獎紀錄將於該時段結束或紅包搶完後的10分鐘內顯示。</li>
        <li>
          如玩家於該時段結束後10分鐘內未選擇紅包，則系統將自動為您選擇並領取未開啟之紅包獎勵。
        </li>
        <li>
          每個時段皆會更換新的一組紅包牆獎勵，若有獎勵未開出，不會將未開獎項之數量延遞至下個時段。
        </li>
        <li>
          如有任何因手機、平板、電腦、網路、電話等之軟硬體技術問題或個人線路不穩、未依遊戲設定完整操作、伺服器維護導致斷線、連接失敗等不可歸責於本公司之事由，而使系統誤送活動訊息或得獎通知，或使參賽玩家所寄出或登錄之資料有延遲、遺失、錯誤、無法辨識或毀損之情況，進而影響活動參與，本公司均不負責，活動照常舉行，不另做補償。
        </li>
        <li>官方保有調整或修改活動規則的權益。</li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>iOS下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
        <span>
          <span style={{ color: '#f5e61c' }}>Android下載：</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.jixi.android.gbao888'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
